.login {
  .login__main {
    padding: 0 1.5em;
    width: calc(100% - 3em);
    max-width: 600px;

    p {
      font-size: 0.875em;
      text-align: center;

      &.--secondary {
        color: var(--theme-sec-text);
      }
    }

    hr {
      border-width: 1px 0 0;
    }

    .login__form {
      padding: 1em 0;
    }
  }
}
