.account {
  .account__popup,
  .account__main > * {
    width: 100%;
    max-width: 909px;
  }

  .account__main {
    position: absolute;
    padding: 0 1.5em 2em;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    border-top-left-radius: var(--popup-radius);
    border-top-right-radius: var(--popup-radius);
    background-color: var(--theme-sec-bg);
    transition: top 300ms;
    box-sizing: border-box;
    overflow-y: auto;

    & > button.button {
      color: var(--theme-text);
      background-color: var(--theme-bg);
    }
  }

  .account__popup .account__header {
    padding: 2em 0 1em 0;
    position: -webkit-sticky;
    position: sticky;
    top: calc(2em + 4px);
    z-index: 2;
  }

  .account__header {
    width: 100%;
    padding-top: 2em;
    padding-bottom: 1em;
    text-align: center;
    background-color: var(--theme-sec-bg);
  }

  .hiddenMenu {
    position: absolute;
    top: 5%;
    left: 2em;
    right: 2em;
    display: flex;
    justify-content: center;

    & > div {
      width: 100%;
      max-width: 300px;
    }

    .menu__title {
      margin-bottom: 1.5em;
      text-align: center;
      font-size: 1.25em;
    }

    .menu__buttons {
      display: flex;
      justify-content: space-between;

      .menu__button {
        padding: 0.8em;
        margin: 0 0.5em;
        background-color: var(--theme-sec-bg);
        border-radius: var(--default-radius);

        & > svg {
          margin-bottom: 0.5em;
          width: 1.6em;
          height: auto;

          g {
            #Rectangle_744,
            line,
            path {
              stroke: var(--theme-text);
            }
          }
        }

        .menu__arrow {
          width: 1.6em;
          height: 1.6em;
          background-color: var(--black);
          flex-shrink: 0;
          border-radius: 50%;
          align-self: flex-end;
        }

        .button__desc {
          font-size: 0.75em;
        }
      }
    }
  }

  .title {
    text-align: center;
  }

  .account__status {
    margin: 0.5em 0;
    width: 100%;
  }

  .account__icon svg {
    width: 1.2em;
    height: 1.2em;
  }

  .summary {
    .list {
      padding: 0;
      & > div:not(.list__element) {
        width: 100%;
        max-width: 408px;
      }

      .skeleton__title {
        width: 40%;
        margin-top: 0.5em;
      }

      .skeleton__desc {
        width: 80%;
      }

      .skeleton__button {
        width: 20%;
      }

      .list__element {
        height: auto;
        background-color: var(--theme-bg);

        &.--big {
          max-height: unset;
          aspect-ratio: unset;
        }

        &.--skeleton {
          div {
            width: 100%;

            & > span:first-child {
              margin-top: 0;
            }
          }
        }

        .element__title {
          padding: 0.25em 0;
        }

        .element__desc:not(.element__desc:last-child) {
          padding-bottom: 0.5em;
        }
      }
    }
  }

  .button__newMag {
    width: 100%;
  }

  .input__default label {
    background-color: var(--theme-sec-bg);
  }

  p {
    font-weight: var(--font-weight-medium);
  }

  .cross {
    position: relative;
    width: 1em;
    height: 1em;

    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 50%;
      width: 2px;
      height: 100%;
      background-color: white;
    }

    &::before {
      transform: rotate(-45deg);
    }

    &::after {
      transform: rotate(45deg);
    }
  }

  .account__flyingButton {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
  }

  .account__list {
    margin-bottom: 2em;

    .list__more {
      padding: 1em;
      font-size: 0.875em;
      color: var(--red);
      text-align: center;
      font-weight: var(--font-weight-medium);
    }

    .list__item {
      position: relative;
      padding: 1em;
      border: solid var(--additional-color);
      border-width: 1px 0 0 0;

      &:last-child {
        border-width: 1px 0;
      }

      .icon--close {
        background-color: var(--theme-text);
      }

      &.--label {
        margin-top: 2em;
      }

      .item__label {
        position: absolute;
        top: -1.75em;
      }

      .circle {
        width: 1.6em;
        height: 1.6em;
        border: 1px solid var(--theme-text);
        border-radius: 50%;
      }

      .item__text {
        color: var(--theme-sec-text);
        font-size: 0.875em;
      }

      .item__icon {
        width: 4em;

        svg g path {
          fill: var(--theme-text);
        }
      }

      .item__icon--right {
        flex-shrink: 0;
        text-align: center;
        width: 36px;
      }

      .item__desc {
        > div:first-child {
          font-size: 0.875em;
          font-weight: var(--font-weight-medium);
        }

        > div:not(:first-child) {
          margin-top: 0.5em;
          font-size: 0.75em;
          color: var(--theme-sec-text);
        }

        div.input__default {
          font-size: unset;
          input.input {
            padding: 0.5rem;
          }
        }
      }
    }
  }
}
