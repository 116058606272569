.dashboard {
  .option.--selected.--secondary {
    border-color: transparent;
  }
  .account__status {
    padding: 0 1.5em;
  }

  &__title {
    padding: 0 1.5em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    h2 {
      padding-top: 10px;
      font-size: 16px;
      font-weight: 600;
      margin: 0;
    }

    > div {
      padding-top: 10px;
      display: flex;
      align-items: center;

      span {
        font-size: 12px;
        font-weight: 500;
        color: var(--gray-50);
      }

      .icon {
        background-color: var(--gray-50);
      }
    }
  }

  &__shortcuts {
    padding: 0 1.5em;
  }

  hr {
    border-color: var(--bottombar-border);
  }
}

.dashboard__favorite {
  padding: 0 1.5em;
  margin: 1em 0;

  display: flex;
  gap: 14px;
  align-items: center;
  overflow: scroll;

  // scroll hide
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
}

.favorite__card {
  min-width: 78%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  min-height: 165px;

  background-color: var(--black);
  color: white;
  border-radius: 10px;

  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .badge {
      display: flex;
      gap: 4px;
      align-items: center;
      background-color: #2d2d2d;
      border-radius: 5px;
      padding: 6px 16px 6px 8px;

      span {
        font-size: 11px;
        font-weight: 600;
      }

      .icon {
        background-color: white;
        width: 14px;
        height: 14px;
        mask-size: 14px;
        -webkit-mask-size: 14px;
      }
    }

    .options {
      .icon {
        background-color: white;
      }
    }
  }

  &__body {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .status {
      display: flex;
      align-items: center;
      gap: 6px;
      span {
        display: block;
        width: 7px;
        height: 7px;
        border-radius: 100%;
        &.red {
          background-color: var(--red);
        }
      }
      font-size: 12px;
    }

    .arrow {
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #2d2d2d;
      border-radius: 100%;

      .icon {
        background-color: white;
      }
    }
  }
}

.popup__shortcuts {
  width: 100%;
  .favorite,
  .rest {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-bottom: 1.5em;
    h3 {
      margin: 0;
    }
  }
  .favorite__item {
    display: flex;
    align-items: center;
    width: 100%;
    height: 40px;
    background-color: var(--theme-bg);
    border-radius: 10px;
    span {
      font-weight: 600;
    }
  }
  .iconWrap {
    margin: 0 10px;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .icon {
      background-color: white;
      width: 16px;
      height: 16px;
      mask-size: 16px;
      -webkit-mask-size: 16px;
    }
    &.--red {
      background-color: var(--red);
    }
    &.--green {
      background-color: var(--green);
    }
  }
}

.popup__favorite {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-bottom: 1em;

  .favorite__item {
    border: 1px solid transparent;
    display: flex;
    align-items: center;
    width: calc(100% - 2em);
    background-color: var(--theme-bg);
    border-radius: 10px;
    padding: 1em;
    &__left{
      flex-grow: 1;
    }
    span {
      font-weight: 500;
      font-size: 1em;
      color: var(--gray-50);
    }
    h2{
      margin:0;
      font-weight: 600;
      font-size: 1em;
    }
    &.--checked{
      border-color: var(--red);
      .icon--heart{
        mask-image: url("../../assets/icons/heart-fill.svg");
        -webkit-mask-image: url("../../assets/icons/heart-fill.svg");
        background-color: var(--red);
      }
    }
  }
}
