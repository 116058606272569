.pin__form {
  margin-bottom: 0.75em;

  &.--secondary input {
    margin: 0 0.2rem;
    width: 2.8rem;
    height: 3.5rem;
    background: none;
    border-width: 1.5px;
    border-color: var(--dark-grey);
  }

  &.--warning input {
    -webkit-animation: blink 1.5s linear infinite;
            animation: blink 1.5s linear infinite;
  }

  input {
    width: 3rem;
    height: 4rem;
    margin: 0 0.25rem;
    text-align: center;
    font-family: inherit;
    font-size: 1.8em;
    color: var(--theme-text);
    background-color: var(--theme-sec-bg);
    font-weight: bold;
    border-radius: var(--default-radius);
    border: 2px solid var(--theme-sec-bg);
    box-sizing: border-box;

    &:valid,
    &:focus {
      border-width: 2px;
    }
  }

  @-webkit-keyframes blink {
    50% {
      color: var(--red);
      border-color: var(--red);
    }
  }

  @keyframes blink {
    50% {
      color: var(--red);
      border-color: var(--red);
    }
  }
}