.chat {
  p {
    text-align: center;
    color: var(--theme-sec-text);
    font-size: 0.75em;
  }

  h4 {
    text-align: center;
  }

  .chat__group,
  .chat__right,
  .chat__left {
    width: 100%;
    height: 100%;
    display: flex;
  }

  .chat__group {
    justify-content: space-between;
  }

  .chat__right,
  .chat__left {
    flex-direction: column;
  }

  .chat__left {
    max-width: 400px;
  }

  .chat__main {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: var(--theme-sec-bg);
    border-top-left-radius: var(--popup-radius);
    border-top-right-radius: var(--popup-radius);

    .chat__header {
      margin: 1em 0;
      text-align: center;

      div.--termina {
        font-size: 0.8125;
      }
    }

    .list__footer {
      margin: 0.5em;
      flex-direction: column;

      hr {
        width: 10%;
        border-width: 1px 0 0;
      }
    }
  }

  .chat__list {
    display: flex;
    flex-direction: column;
    word-break: break-word;
    overflow-y: auto;

    &.--high {
      width: 100%;
      flex-direction: column-reverse;
      padding-bottom: 0.5em;
      flex-basis: 100%;

      & > div {
        display: flex;
        flex-direction: column;
      }
    }

    .list__contact {
      margin: 1em;

      .contact__avatar {
        min-width: 4.25em;
        min-height: 4.25em;
        background-color: var(--black);
        border-radius: 50%;

        #logo path {
          fill: var(--border-grey);
        }
      }

      .contact__desc {
        padding: 0.5em;
        flex-basis: 90%;

        .--termina {
          font-size: 0.8125em;
        }

        .chat__text {
          display: -webkit-box;
          text-overflow: ellipsis;
          overflow: hidden;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
        }

        div {
          padding: 0.25em;
        }
      }

      .contact__time {
        white-space: nowrap;
      }
    }

    .chat__messgage {
      margin: 0.25em 1em;
      padding: 1em;
      max-width: 70%;
      border-radius: var(--default-radius);

      div {
        font-size: 0.875em;

        &:last-child {
          text-align: end;
          margin-top: 0.875em;
        }
      }

      .message__files {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 0.5em;

        a {
          color: var(--theme-text);
        }

        img {
          width: 5rem;
          height: auto;
        }
      }

      &.--client {
        align-self: flex-end;
        color: var(--theme-sec-text);
        background-color: var(--theme-bg);
        border-bottom-right-radius: 0;
      }

      &.--service {
        align-self: flex-start;
        color: var(--theme-sec-bg);
        background-color: var(--theme-text);
        border-bottom-left-radius: 0;

        a {
          color: var(--theme-sec-bg);
        }
      }
    }
  }

  .chat__input {
    width: 100%;
    padding: 1em 1em 1.5em;
    box-sizing: border-box;
    box-shadow: 0 -0.5em 1em var(--shadow);

    & > * {
      margin: 0 0.5em;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    .paperclip__main {
      position: relative;
      display: flex;

      .paperclip__counter {
        position: absolute;
        top: -0.4em;
        right: -0.6em;
        width: 1em;
        height: 1em;
        background-color: var(--red);
        border-radius: 50%;

        span {
          color: white;
          font-size: 0.75em;
          font-weight: var(--font-weight-semi-bold);
        }
      }
    }

    .input__main {
      flex-basis: 100%;
      position: relative;

      textarea {
        width: 100%;
        padding: 1em;
        padding-right: 3.4em;
        font-size: 1em;
        font-family: inherit;
        color: var(--theme-text);
        background: var(--theme-bg);
        border-radius: var(--button-radius);
        border: none;
        box-sizing: border-box;
      }

      svg {
        position: absolute;
        top: 1em;
        right: 1em;
      }
    }
  }

  .chat__text {
    color: var(--theme-sec-text);
    font-size: 0.75em;
  }
}
