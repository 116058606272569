.calendar {
  .calendar__main {
    .center__main {
      width: 100%;
      max-width: 340px;
    }

    .calendar__inputs {
      .calendar__input {
        margin: 0.5em;
        width: 40%;
        min-width: 120px;
        max-width: 200px;

        .input__shadow {
          div {
            margin: -0.25em 1em -0.25em;
            height: 1em;
            background-color: var(--theme-sec-bg);
            border-top-left-radius: var(--calendar-radius);
            border-top-right-radius: var(--calendar-radius);
            opacity: 0.75;
          }

          div:first-child {
            margin: 0 2em;
            opacity: 0.5;
          }
        }

        .input__main {
          padding: 1em 0.5em;
          text-align: center;
          background-color: var(--theme-sec-bg);
          border-radius: var(--calendar-radius);

          div {
            padding: 0.2rem;

            &:first-child {
              font-size: 0.75em;
              color: var(--red);
            }
          }

          hr {
            width: 20%;
            border-width: 1px 0 0;
          }

          .input__day {
            font-size: 2.9375em;
          }

          .input__year {
            font-size: 0.875em;
          }
        }
      }
    }

    .calendar__range {
      margin: 0 1.5em;
      width: calc(100% - 3em);
      display: flex;
      justify-content: center;

      .range .range__input .range__line .range__part span:not(.--selected) {
        color: var(--theme-text);
      }
    }

    .calendar__info {
      background-color: var(--theme-sec-bg);
      border-radius: 10px;
      padding: 1.5em;
      text-align: left;
      p {
        font-size: 0.75rem;
        font-weight: 600;
        margin: 0;
        margin-bottom: 1em;
      }
      div:has(span) {
        line-height: 1.5;
      }
    }

    p {
      font-size: 0.875em;
    }

    .calendar__info {
      margin: 0.875rem;
      font-size: 0.75em;

      div {
        font-weight: var(--font-weight-medium);

        &:first-child {
          margin-bottom: 0.25rem;
          font-weight: var(--font-weight-semi-bold);
        }
      }
    }
  }

  .range .range__input .range__line .range__part span {
    min-width: 12px;
  }

  .popup__bg.--close {
    -webkit-animation-name: moveOut;
    animation-name: moveOut;
  }

  .popup__calendar {
    -webkit-animation: moveIn 300ms ease-out;
    animation: moveIn 300ms ease-out;
    width: 100%;

    .popup__shadow {
      div {
        margin: -0.5em 1.5em -0.5em;
        height: 1.25em;
        background-color: var(--black);
        border-top-left-radius: var(--popup-radius);
        border-top-right-radius: var(--popup-radius);
        opacity: 0.85;
      }

      div:first-child {
        margin: 0 3em;
        opacity: 0.75;
      }
    }

    .popup__main {
      padding: 2em 2em 3em;
      flex-direction: column;
      background-color: var(--black);
      border-top-left-radius: var(--popup-radius);
      border-top-right-radius: var(--popup-radius);

      .popup__inputs {
        margin: 1em 0;
        display: flex;
        align-items: flex-end;
        justify-content: center;

        .popup__input {
          margin: 0.5em;
          width: 40%;
          padding: 0.5em;
          color: var(--border-grey);
          border-bottom: 2px solid var(--border-grey);
          opacity: 0.8;

          span {
            display: flex;
            justify-content: center;

            b {
              margin-left: 0.5em;
            }
          }

          &.--selected {
            opacity: 1;
            color: var(--red);
            border-bottom-color: var(--red);
          }
        }
      }

      .range .range__line .range__part .range__thumb {
        border-color: var(--black);
      }

      .popup__button {
        margin-top: 4em;
        padding: 0.75em;
        color: white;
        font-size: 0.875em;
        text-align: center;
        border: 1px solid white;
        border-radius: var(--default-radius);
      }
    }
  }

  .range {
    margin: 1em 0 2em;
    width: 100%;
    max-width: 900px;
  }

  .react-calendar {
    max-width: 600px;
    min-height: 258px;

    &.--light {
      .react-calendar__month-view__weekdays__weekday,
      button {
        color: var(--theme-text);
      }

      button {
        &.react-calendar__tile--active,
        &.react-calendar__tile--rangeStart,
        &.react-calendar__tile--rangeEnd {
          border-color: var(--theme-bg);
          color: white;
          background-color: var(--red);
        }
      }
    }

    .react-calendar__month-view__weekdays__weekday {
      padding: 0.6em 0;
      color: white;

      abbr {
        text-decoration: none;
        font-weight: var(--font-weight-semi-bold);
      }
    }

    .react-calendar__navigation {
      display: flex;
    }

    button {
      margin: 0;
      padding: 0.6em;
      background: none;
      color: white;
      font-family: inherit;
      border: none;

      &.react-calendar__navigation__arrow {
        padding: 0.6em 1.6em;

        &.react-calendar__navigation__prev2-button,
        &.react-calendar__navigation__next2-button {
          display: none;
        }
      }

      &.react-calendar__tile--active,
      &.react-calendar__tile--rangeStart,
      &.react-calendar__tile--rangeEnd {
        border: solid var(--black);
        border-width: 1px 0;
        background-color: rgba(#eb003d, 0.6); //#303030;
      }

      &.react-calendar__tile--rangeStart {
        border-top-left-radius: var(--default-radius);
        border-bottom-left-radius: var(--default-radius);
      }

      &.react-calendar__tile--rangeEnd {
        border-top-right-radius: var(--default-radius);
        border-bottom-right-radius: var(--default-radius);
      }

      &:disabled {
        opacity: 0.5;
      }
    }
  }
}
