.dropDown {
  position: relative;

  .dropDown__list {
    padding: 1em 2em;
    position: absolute;
    right: -1em;
    display: flex;
    flex-direction: column;
    background-color: var(--theme-sec-bg);
    border-radius: var(--default-radius);
    z-index: 1;

    .dropDown__item {
      color: var(--theme-text);

      p {
        font-size: 115%;
      }

      &.--selected {
        color: var(--red);
      }
    }
  }
}
