$height: calc((var(--vh, 1vh) * 100) - 64px);
$summaryBg: #202020;
$blockBg: #2c2c2d;
$popupBg: var(--black);

.basketPopup {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  color: white;
  background-color: $popupBg;
  -webkit-animation: startPopupAnimation 300ms ease-out;
  animation: startPopupAnimation 300ms ease-out;
  z-index: 1;

  &__list {
    -webkit-animation: startPopupListAnimation 500ms ease-out;
    animation: startPopupListAnimation 500ms ease-out;
  }

  &__summary {
    -webkit-animation: startPopupSummaryAnimation 500ms ease-out;
    animation: startPopupSummaryAnimation 500ms ease-out;
  }

  @-webkit-keyframes startPopupAnimation {
    0%,
    10% {
      height: 44px;
      border-bottom-left-radius: var(--popup-radius);
      border-bottom-right-radius: var(--popup-radius);
    }
    100% {
      height: 100%;
      border-bottom-left-radius: var(--popup-radius);
      border-bottom-right-radius: var(--popup-radius);
    }
  }

  @keyframes startPopupAnimation {
    0%,
    10% {
      height: 44px;
      border-bottom-left-radius: var(--popup-radius);
      border-bottom-right-radius: var(--popup-radius);
    }
    100% {
      height: 100%;
      border-bottom-left-radius: var(--popup-radius);
      border-bottom-right-radius: var(--popup-radius);
    }
  }

  @-webkit-keyframes endPopupAnimation {
    0% {
      height: 100%;
      border-bottom-left-radius: var(--popup-radius);
      border-bottom-right-radius: var(--popup-radius);
    }
    90%,
    100% {
      height: 44px;
      border-bottom-left-radius: var(--popup-radius);
      border-bottom-right-radius: var(--popup-radius);
    }
  }

  @keyframes endPopupAnimation {
    0% {
      height: 100%;
      border-bottom-left-radius: var(--popup-radius);
      border-bottom-right-radius: var(--popup-radius);
    }
    90%,
    100% {
      height: 44px;
      border-bottom-left-radius: var(--popup-radius);
      border-bottom-right-radius: var(--popup-radius);
    }
  }

  @-webkit-keyframes startPopupListAnimation {
    0% {
      opacity: 0;
    }
    60% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes startPopupListAnimation {
    0% {
      opacity: 0;
    }
    60% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-webkit-keyframes endPopupListAnimation {
    0% {
      opacity: 1;
    }
    40% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }

  @keyframes endPopupListAnimation {
    0% {
      opacity: 1;
    }
    40% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }

  @-webkit-keyframes startPopupSummaryAnimation {
    0% {
      opacity: 0;
    }
    60% {
      opacity: 0;
      margin-bottom: -40%;
    }
    100% {
      opacity: 1;
      margin-bottom: 0;
    }
  }

  @keyframes startPopupSummaryAnimation {
    0% {
      opacity: 0;
    }
    60% {
      opacity: 0;
      margin-bottom: -40%;
    }
    100% {
      opacity: 1;
      margin-bottom: 0;
    }
  }

  @-webkit-keyframes endPopupSummaryAnimation {
    0% {
      opacity: 1;
      margin-bottom: 0;
    }
    40% {
      opacity: 0;
      margin-bottom: -40%;
    }
    100% {
      opacity: 0;
    }
  }

  @keyframes endPopupSummaryAnimation {
    0% {
      opacity: 1;
      margin-bottom: 0;
    }
    40% {
      opacity: 0;
      margin-bottom: -40%;
    }
    100% {
      opacity: 0;
    }
  }

  &__bg {
    position: fixed;
    width: 100%;
    top: 64px;
    left: 0;
    right: 0;
    height: $height;
    background-color: var(--toast-bg);
    -webkit-animation: darken 500ms ease-in;
    animation: darken 500ms ease-in;
    z-index: 20;

    &.--close {
      -webkit-animation-duration: 510ms;
      animation-duration: 510ms;
      -webkit-animation-name: lighten;
      animation-name: lighten;

      .basketPopup {
        -webkit-animation-name: endPopupAnimation;
        animation-name: endPopupAnimation;
        -webkit-animation-duration: 310ms;
        animation-duration: 310ms;
        -webkit-animation-delay: 200ms;
        animation-delay: 200ms;

        &__list {
          -webkit-animation-name: endPopupListAnimation;
          animation-name: endPopupListAnimation;
          -webkit-animation-duration: 510ms;
          animation-duration: 510ms;
        }

        &__summary {
          -webkit-animation-name: endPopupSummaryAnimation;
          animation-name: endPopupSummaryAnimation;
          -webkit-animation-duration: 510ms;
          animation-duration: 510ms;
        }
      }
    }
  }

  .icon {
    background-color: white;
  }

  &__list {
    padding-bottom: calc(30px + 1rem);
    flex: 1;
    overflow: auto;

    &__item {
      &:not(:last-child) {
        margin-bottom: 1rem;
      }

      &__block {
        padding: 5px 1rem;
        margin-bottom: 1px;
        background-color: $blockBg;

        .input__checkbox {
          label {
            font-size: 0.75rem;
            font-weight: 500;
            color: white;
          }
          input:checked[type="checkbox"] {
            border-color: var(--red);
            background-color: var(--red);
          }

          input[type="checkbox"] {
            width: 20px;
            height: 20px;
            border-radius: 50%;
          }
        }
      }

      &__header {
        padding-top: 1rem;
        padding-bottom: 1rem;
        display: flex;
        align-items: center;
        gap: 1rem;
        img {
          height: 67px;
        }
        &__title {
          :first-child {
            font-family: "termina";
            font-weight: 700;
          }
          :nth-child(2) {
            margin-top: 6px;
            font-size: 0.75rem;
            font-weight: 500;
          }
        }
      }

      &__button {
        margin: 5px 0;
        padding: 0.5em 0.75em 0.5em 1.25em;
        display: flex;
        align-items: center;
        gap: 0.5em;
        font-size: 0.75rem;
        font-weight: 500;
        background-color: $popupBg;
        border-radius: var(--button-radius);
      }

      &__row {
        padding: 8px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 0.5em;
        font-size: 0.75rem;
        > div {
          display: flex;
          align-items: center;
          gap: 10px;

          .icon {
            width: 16px;
            height: 16px;
          }

          .icon--lock {
            -webkit-mask-size: 18px;
            mask-size: 18px;
          }
        }
        :first-child {
          color: var(--theme-sec-text);
        }
        :nth-child(2) {
          font-weight: 500;
        }
      }

      &__sum {
        :nth-child(2) {
          font-family: "termina";
          font-size: 0.875rem;
          font-weight: 700;
        }
      }

      span {
        &.--title {
          color: white;
          font-weight: 500;
        }

        &.--smaller {
          color: var(--theme-sec-text);
          font-size: 0.625rem;
          font-weight: 400;
        }

        &.--button {
          cursor: pointer;
        }

        &.--red {
          color: var(--red);
        }
      }
    }
  }

  &__summary {
    margin-top: -30px;
    position: relative;
    background-color: $summaryBg;
    border-top-left-radius: var(--popup-radius);
    border-top-right-radius: var(--popup-radius);
    z-index: 2;

    &__bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      -webkit-backdrop-filter: blur(2px);
      backdrop-filter: blur(2px);
      z-index: 1;
    }

    &__header {
      padding: 0.75em;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 0.75rem;
      font-weight: 600;
      gap: 0.25em;
      cursor: pointer;

      &__subTitle {
        text-align: center;
        font-size: 0.675rem;
        color: var(--theme-sec-text);
      }
    }

    &__row {
      margin: 1rem 1.75rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 0.5em;
    }

    &__main {
      padding: 1.5rem 1.75rem;
      background-color: $popupBg;
      border-top-left-radius: var(--popup-radius);
      border-top-right-radius: var(--popup-radius);
      & > * {
        flex-basis: 40%;
      }
      button {
        margin: 0;
      }
    }

    &__price {
      :first-child {
        color: var(--theme-sec-text);
        font-size: 0.75rem;
      }
      :nth-child(2) {
        margin-top: 4px;
        font-family: "termina";
        font-weight: 700;
      }
    }
  }
}
