.popup__reservationFailed {
  color: var(--theme-text);
  display: grid;

  .reservationFailed__header {
    margin: 1.25em 15%;
    text-align: center;
    font-size: 1.125em;
    font-weight: var(--font-weight-semi-bold);

    span {
      width: 225px;
    }
  }

  .reservationFailed__list {
    .item__header {
      margin: 1em 0;

      & > div:first-child {
        font-weight: var(--font-weight-semi-bold);
      }

      & > div:nth-child(2) {
        margin-top: 0.35em;
        font-size: 0.875em;
        color: var(--theme-sec-text);
      }
    }

    .item__decs {
      &:not(&:last-child) {
        margin-bottom: 0.5em;
      }

      & > div:first-child {
        color: var(--theme-sec-text);
        font-size: 0.75em;
      }

      & > div:last-child {
        font-size: 0.875em;
        font-weight: var(--font-weight-semi-bold);
      }

      svg {
        margin-right: 0.5em;
        max-width: 17px;
        height: auto;

        line,
        rect,
        #Ellipse_402 {
          stroke: var(--theme-text);
        }

        path {
          fill: var(--theme-text);
        }

        &.terminate {
          line,
          circle {
            stroke: var(--theme-text);
            stroke-width: 2;
          }
        }
      }

      div.--big {
        font-size: 2.1875em;
      }
    }

    hr {
      margin-top: 1em;
      border-width: 1px 0 0 0;
      border-style: dashed;
    }
  }

  .reservationFailed__buttons {
    margin-top: 0.75em;
    .button.--arrow {
      margin-top: 0.75em;
    }
  }
}
