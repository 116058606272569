.warehouse__slide {
  width: 216px;
  margin: 2em 1em;

  &.commercial {
    height: 295px;

    & > .slide {
      width: 197px;
      height: 215px;

      .warehouse__commercial {
        width: 100%;
        height: 100%;
        padding: 1em;
        flex-direction: column;
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%237c7c7c' stroke-width='4' stroke-dasharray='30%2c 20' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
        box-sizing: border-box;

        .commercial__title {
          font-size: 0.5em;
          margin-bottom: 1em;
        }
      }
    }
  }

  .slide {
    transition: all 200ms;

    &:not(.--selected) {
      opacity: 0.75;
      transform: scale(0.9);
    }

    &.--selected {
      transform: scale(1.1);
    }
  }

  @media screen and (max-height: 640px) {
    margin: 0;
  }
}

.warehouses {
  .title.--out {
    -webkit-animation: fadeOut 200ms;
            animation: fadeOut 200ms;
  }

  .warehouses__slides {
    & > div {
      width: 100%;
      // max-width: 900px;
    }
  }

  .warehouse__info {
    padding: 0 0.5em;
    text-align: center;

    .info__text {
      margin: 1em;

      div:first-child {
        font-size: 0.8125em;
        margin-bottom: 0.3em;
        white-space: nowrap;
      }

      div {
        font-size: 0.75em;
        font-weight: var(--font-weight-medium);
      }
    }
  }
}
