.keyboard--numeric {
  margin: 1em 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .keyboard__row {
    display: flex;

    .keyboard__key {
      margin: 0.5em 1em;
      width: 5.125em;
      height: 5.125em;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background-color: var(--button-bg);

      span {
        font-size: 2em;
        font-weight: var(--font-weight-medium);
      }

      &.--backspace {
        background: none;

        span {
          font-size: 1.1875em;
          font-weight: var(--font-weight-semi-bold);
        }
      }

      &.--ok {
        color: white;
        background-color: var(--red);

        span {
          font-size: 1.1875em;
          font-weight: var(--font-weight-semi-bold);
        }
      }
    }
  }
}

.keyboard--default {
  margin: 2em 0;
  width: 100%;

  .keyboard__row {
    margin: 1.5em 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;

    button {
      min-width: 1.5em;
      font-size: 1.5em;
      padding: 0.15em;
      font-family: inherit;
      color: var(--theme-text);
      background: none;
      border: none;

      &.button__shift,
      &.button__backspace {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
