.categories {
  .categories__menu--top {
    width: 100%;
    background-color: var(--black);
    border-bottom-left-radius: var(--popup-radius);
    border-bottom-right-radius: var(--popup-radius);
    box-sizing: border-box;

    img {
      transform: scale(0.8);
    }
  }

  .categories__black {
    background-color: var(--black);
  }

  .categories__menu--bottom {
    position: -webkit-sticky;
    position: sticky;
    width: 100%;
    top: 62px;
    background-color: var(--black);
    border-bottom-left-radius: var(--popup-radius);
    border-bottom-right-radius: var(--popup-radius);
    z-index: 5;

    .menu__list {
      padding: 1em;

      .menu__element {
        padding: 0.5em;
        color: var(--dark-grey);
        font-size: 0.75em;
        font-weight: var(--font-size-medium);

        &.--selected {
          position: relative;
          color: white;

          &::after {
            content: "";
            position: absolute;
            top: 22px;
            left: 50%;
            width: 5px;
            height: 5px;
            transform: translateX(-50%);
            background-color: white;
            border-radius: 50%;
          }
        }
      }
    }
  }

  .categories__header {
    padding: 2em 1.5em 0;

    .header__title {
      font-size: 0.875em;
      font-weight: var(--font-weight-semi-bold);
    }

    .header__sort {
      display: flex;
      align-items: center;

      div {
        font-size: 0.875em;
      }

      svg {
        padding-right: 0.5em;
      }
    }
  }

  .list .list__element {
    &.--black {
      .element__price,
      .element__title div:first-child {
        color: white;
      }

      .element__price {
        background-color: #262627;
      }

      svg#button {
        rect {
          fill: #262627;
        }

        #arrow-right > * {
          stroke: var(--light-grey);
        }
      }
    }

    .element__title {
      div:first-child {
        color: var(--theme-text);
        font-size: 0.8125em;
        -webkit-margin-after: 0.5em;
                margin-block-end: 0.5em;
      }

      div {
        color: var(--dark-grey);
        font-size: 0.75em;
      }
    }

    .skeleton__title {
      margin: 0.25em 0;
      height: 1.2em;
    }

    .element__title--subCategories {
      display: flex;
      align-items: center;

      svg {
        margin-right: 0.5em;
      }
    }

    .element__size {
      padding-top: 0.65em;
      font-size: 0.75em;
      color: var(--dark-grey);

      span {
        color: var(--theme-text);
        font-weight: var(--font-weight-semi-bold);
      }

      &:last-child {
        padding-bottom: 1em;
      }
    }

    .element__desc {
      padding: 0 0.5em;
      color: var(--dark-grey);
      font-size: 0.625em;
    }

    .element__location {
      display: flex;
      align-items: center;

      div {
        margin-left: 0.5em;
        width: -webkit-min-content;
        width: -moz-min-content;
        width: min-content;
        font-size: 0.625em;
      }
    }

    .element__price {
      padding: 0.3em 0.7em;
      flex-shrink: 0;
      background-color: var(--theme-bg);
      border-radius: var(--button-radius);

      span {
        font-size: 0.75em;
      }

      div:first-child {
        font-size: 0.625em;
      }

      div {
        font-size: 0.875em;
      }
    }

    .skeleton__price {
      width: 4em;
      height: 1.5em;
    }

    .skeleton__dots {
      width: 0.5em;
      height: 1.5em;
    }

    .skeleton__forward {
      width: 2em;
      height: 2em;
    }
  }
}
