.additional .list .list__element {
  border: 1px solid transparent;

  &.--selected {
    border-color: var(--red);

    .element__group .element__header {
      color: white;
      background-color: var(--red);
    }
  }

  .element__group .element__header {
    padding: 0.5em 0.75em;
    font-size: 0.75em;
    background-color: var(--additional-color);
    border-radius: var(--button-radius);
  }

  .element__title {
    font-size: 0.8125em;
  }

  .element__desc div {
    padding: 0.1em 0;
    color: var(--dark-grey);
    font-size: 0.6875em;
    font-weight: var(--font-weight-medium);

    span {
      color: var(--red);
    }

    b {
      color: var(--theme-sec-text);
    }
  }
}

.additional {
  .list{
    padding: 0 1em 1em 1em !important;
  }
}