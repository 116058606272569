.locks {
  &__section {
    .title {
      span.error {
        position: relative;
        display: none;
        font-size: 1em;
        color: var(--red);
        padding-top: 4px;
        &.active {
          display: block;
        }
      }
    }
    .content {
      padding: 0.5em 1em;
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  }
  .badge {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px 12px;
    font-size: 0.875em;
    background-color: var(--theme-bg);
    border-radius: 5px;
  }

  .checkCircle {
    width: 18px;
    height: 18px;
    border-radius: 100%;
    background-color: var(--theme-bg);
    .icon {
      width: 18px;
      height: 18px;
      -webkit-mask-size: 18px;
      mask-size: 18px;
      background-color: var(--theme-sec-bg);
    }
  }
  .grid2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

.lockItem {
  border: 1px solid transparent;
  background-color: var(--theme-sec-bg);
  border-radius: 10px;
  padding: 1em;
  display: flex;
  align-items: center;
  gap: 10px;

  h3 {
    margin: 0;
    font-size: 0.875em;
    font-weight: 600;
  }

  span {
    font-size: 0.75em;
    color: var(--dark-grey);
  }

  .iconWrap {
    background-color: var(--theme-bg);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 42px;
    height: 42px;
    border-radius: 5px;
  }

  &__center {
    flex-grow: 1;
  }

  &.checked {
    border: 1px solid var(--red);
    .checkCircle {
      background-color: var(--red);
    }
  }
}

.cardItem {
  border: 1px solid transparent;
  background-color: var(--theme-sec-bg);
  border-radius: 10px;
  padding: 1em;

  h2 {
    margin: 0;
    font-size: 1em;
    font-weight: 600;
  }

  span {
    font-size: 0.875em;
    color: var(--red);
  }

  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 1em;
  }

  &__footer {
    p {
      font-size: 0.875em;
      color: var(--dark-grey);
    }
  }

  &.checked {
    border: 1px solid var(--red);
    .checkCircle {
      background-color: var(--red);
    }
  }
}

.infoCard {
  background-color: var(--theme-sec-bg);
  border-radius: 10px;
  padding: 1em;
  p {
    margin: 0;
    font-size: 0.875em;
  }
}

.selectItem {
  border: 1px solid transparent;
  position: relative;
  background-color: var(--theme-sec-bg);
  border-radius: 10px;
  padding: 1em;
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  h2 {
    font-size: 1em;
    margin: 0;
    font-weight: 600;
  }

  span {
    font-size: 0.75em;
    color: var(--dark-grey);
    margin: 0;
  }
  .checkCircle {
    position: absolute;
    top: 16px;
    right: 16px;
  }

  &.checked {
    border: 1px solid var(--red);
    .checkCircle {
      background-color: var(--red);
    }
  }
}

.cardItemAmount {
  background-color: var(--theme-sec-bg);
  border-radius: 10px;
  padding: 1em;
  display: flex;
  align-items: center;

  &__left {
    flex-grow: 1;
    h2 {
      font-size: 1em;
      margin: 0;
      font-weight: 600;
    }
    span {
      font-size: 0.875em;
      color: var(--red);
    }
  }

  &__right {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    .circle {
      width: 24px;
      height: 24px;
      border-radius: 100%;
      background-color: var(--dark-grey);
      display: flex;
      align-items: center;
      justify-content: center;
      .icon {
        background-color: white;
      }
      &:last-child {
        background-color: var(--red);
      }
    }
    div:nth-child(2) {
      font-size: 21px;
      font-weight: 600;
    }
  }
}

.ownershipInfo {
  margin: 1em 1em 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed var(--border-color);
  border-radius: 10px;
  p {
    margin: 0;
    padding: 1em;
    font-size: 0.875em;
    width: 100%;
    word-break: break-word;
  }
}

.popup__padlock,
.popup__rfid {
  min-height: 50vh;
  width: 100%;
  overflow-y: auto;
  &__info {
    background-color: var(--theme-sec-bg);
    padding: 1em;
    border-radius: 10px;
    margin-bottom: 1em;
    h2 {
      font-size: 1em;
      font-weight: 600;
      margin: 0;
    }
    p {
      margin-top: 0.5em;
      margin-bottom: 0;
    }
  }
  &__items {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-bottom: 1em;
    h2 {
      font-size: 1em;
      font-weight: 600;
      margin: 0;
    }
  }
  &__item {
    position: relative;
    border: 1px solid transparent;
    background-color: var(--theme-sec-bg);
    border-radius: 10px;
    padding: 1em;
    display: flex;
    align-items: center;

    &.checked {
      border-color: var(--red);
      .checkCircle {
        background-color: var(--red, red);
      }
    }

    &:has(.popup__rfid__item__marker) {
      margin-top: 12px;
    }

    &__marker {
      position: absolute;
      top: -12px;
      left: 50%;
      transform: translateX(-50%);
      background-color: var(--red);
      padding: 4px 12px;
      border-radius: 5px;
      color: white;
      font-size: 0.875em;
      font-weight: 600;
    }

    &__left {
      flex-grow: 1;
      h3 {
        font-size: 1em;
        margin: 0;
        font-weight: 600;
      }
      & > div {
        margin-top: 0.25em;
        font-size: 0.875em;
        color: var(--dark-grey);
        b {
          font-weight: 600;
        }
      }
    }

    &__right {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 12px;
      .circle {
        width: 24px;
        height: 24px;
        border-radius: 100%;
        background-color: var(--dark-grey);
        display: flex;
        align-items: center;
        justify-content: center;
        .icon {
          background-color: white;
        }
        &:last-child {
          background-color: var(--red);
        }
      }
      div:nth-child(2) {
        font-size: 21px;
        font-weight: 600;
      }
    }
  }

  .checkCircle {
    width: 18px;
    height: 18px;
    border-radius: 100%;
    background-color: var(--theme-bg);
    .icon {
      width: 18px;
      height: 18px;
      -webkit-mask-size: 18px;
      mask-size: 18px;
      background-color: var(--theme-sec-bg);
    }
  }
}

.popup__infoBlocks {
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  .desc {
    text-align: left;
    padding-bottom: 10px;
    background-color: var(--theme-bg);
    border-radius: 10px;
    padding: 1em;
    span {
      font-weight: 600;
    }
  }
  h2 {
    font-weight: 600;
    text-align: left;
    font-size: 1rem;
    margin: 0;
    margin-top: 1em;
  }
  .lockItem--info {
    background-color: var(--theme-bg);
    flex-direction: column;
    div {
      width: 100%;
    }

    div:first-child {
      display: flex;
      align-items: center;
      justify-content: start;
      font-weight: 600;
      gap: 10px;
    }
    div:last-child {
      font-size: 0.875em;
      color: var(--dark-grey);
    }
  }
}
