.range__input {
  width: 100%;
  padding: 1em 0;

  .range__line {
    height: 3px;
    display: flex;
    align-items: flex-start;
    background-color: var(--border-grey);

    &.--dark .range__part .range__thumb {
      border-color: var(--black);
    }

    .range__part {
      position: relative;

      &:first-child {
        .range__thumb.--selected {
          transform: translate(70%, -50%);
        }
        span {
          left: 0;
          right: auto;
          transform: translateX(-20%);
        }
      }

      &:last-child {
        .range__thumb.--selected {
          transform: translate(20%, -50%);
        }
        span {
          transform: translateX(20%);
        }
      }

      .front__line {
        position: relative;
        height: 3px;
        background-color: var(--red);
      }

      &.--active {
        height: 100%;
        background-color: var(--red);
      }

      .range__thumb {
        position: absolute;
        top: -1px;
        right: 0;
        width: 4px;
        height: 4px;
        border: 1px solid var(--theme-bg);
        border-radius: 50%;

        &.--selected {
          top: 0;
          width: 20px;
          height: 20px;
          background-color: var(--red);
          border-width: 3px;
          transform: translate(50%, -50%);
          z-index: 1;
        }
      }

      span {
        position: absolute;
        top: 15px;
        right: 0;
        font-size: 0.625em;
        white-space: nowrap;
        color: var(--border-grey);
        transform: translateX(50%);

        &.--selected {
          color: var(--red);
        }
      }
    }
  }
}
