.filters {
  .filters__list {
    padding: 0.5em 0;
    display: flex;
    overflow-x: auto;

    .filters__item {
      margin-right: 0.5rem;
      font-size: 0.75em;
      display: flex;
      flex-direction: column;
      justify-content: center;
      border: 1px solid var(--theme-text);
      border-radius: var(--button-radius);
      white-space: nowrap;
      z-index: 1;

      .item__label {
        margin: -0.5em 1.5em -0.5em 0.1em;
        padding: 0 0.5em;
        align-self: flex-start;
        background-color: var(--theme-sec-bg);
      }

      .item__content span {
        padding: 1em;
      }
    }
  }

  .filter__button {
    margin-left: 1em;
  }
}

.filter__button span {
  margin-left: 0.5em;
}

.popup--filter {
  position: relative;
  align-self: flex-start;
  width: 100%;

  h2 {
    text-align: center;
  }

  .popup__main {
    & > div:not([class]) {
      min-width: 400px;
      @media screen and (max-width: 600px) {
        min-width: auto;
        width: 100%;
      }
    }

    .popup__list {
      margin: 1.3em;
      max-height: calc(60 * var(--vh, 1vh));
      overflow: auto;

      .element {
        margin: 1em 0;

        .element__date {
          & > div:first-child {
            margin-right: 0.5em;
          }

          & > div:last-child {
            margin-left: 0.5em;
          }

          span {
            padding-right: 0.25em;
          }
        }

        .element__title {
          font-size: 0.875em;
          font-weight: var(--font-weight-semi-bold);
        }

        .range__input {
          padding: 1em 0.5em 2em;
          box-sizing: border-box;
        }

        select,
        input[type="text"],
        input[type="date"],
        input[type="number"] {
          margin: 0.4rem 0;
          padding: 0.55rem 0.75rem;
          font-size: 0.875em;
          font-family: inherit;
          color: var(--theme-text);
          background-color: var(--additional-color);
          border-radius: var(--button-radius);
          border: none;
        }

        input[type="date"] {
          padding: 0.4875rem 0.75rem;
        }

        input[type="number"] {
          width: 40%;
        }

        input[type="checkbox"] {
          width: 1em;
          height: 1em;
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
          border: 1px solid var(--dark-grey);
          border-radius: 2px;
        }

        input:checked[type="checkbox"] {
          border-color: var(--red);
          background-color: var(--red);
          background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
        }

        select {
          padding-right: 2.25em;
          background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
          background-repeat: no-repeat;
          background-position: right 0.5em center;
          background-size: 16px 12px;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
        }

        .element__section {
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;
        }

        .element__checkbox {
          padding: 0.2em 0;
          display: flex;
          align-items: center;
          min-width: 50%;

          label {
            padding-left: 0.2rem;
            font-size: 0.75em;
          }
        }
      }
    }
  }

  .popup__buttons {
    margin: 1em;

    .button__add,
    .button__cancel {
      width: 100%;
      padding: 0.75em 0.4em;
      margin: 0.3em;
      text-align: center;
      border-radius: var(--button-radius);
    }

    .button__cancel {
      border: 1px dashed var(--dark-grey);
    }

    .button__add {
      color: var(--white);
      border: 1px solid var(--red);
      background-color: var(--red);
    }
  }
}
