.popup--comercial {
  color: white;

  .popup__close {
    position: absolute;
    top: 2rem;
    right: 2rem;
    font-size: 2rem;
    font-weight: 600;
  }

  .popup__header {
    margin-bottom: 5rem;
    text-align: center;

    h1 {
      font-size: 2.5em;
    }
  }

  .popup__main {
    text-align: center;
  }
}
