.billing {
  .--element__title{
    padding-top: 1em;
    width: calc(100% - 2em);
    font-size: 1rem;
    font-weight: 600;
    text-align: left;
    margin: 1em 0;
  }
  .billing__form {
    .center .button {
      border-radius: var(--default-radius);

      &:first-child {
        margin-right: 0.5em;
      }

      &:last-child {
        margin-left: 0.5em;
      }
    }

    button.--back {
      margin: 0.5em 0 1em;
    }
  }

  .billing__receipt.summary {
    width: 100%;
    padding: 0;

    .list {
      padding: 1em;
      justify-content: center !important;

      & > div {
        width: 100%;
      }

      .list__element.--big {
        width: 100%;
        padding: 1em;
      }
      .list__header {
        font-size: 1rem;
        margin: 1em 0;
      }
    }
  }

  .billing__menu {
    width: calc(100% - 2em) !important;
    padding: 0 1em !important;
    display: flex;
    justify-content: space-between;

    .menu__item {
      position: relative;
      width: calc(50% - 0.5em);
      border: 1px solid transparent;
      background-color: var(--theme-sec-bg);
      border-radius: var(--default-radius);

      svg {
        padding: 0.5em;
        align-self: flex-start;
      }

      .item__desc {
        padding: 1.5em 1em;
        font-size: 0.875em;
        font-weight: 600;
      }
      &.--selected {
        border-color: var(--red);
        .checkCircle {
          background-color: var(--red);
        }
      }
    }
  }

  .billing__info {
    margin-top: 1em;
    width: 100%;
    display: flex;
    flex-direction: column;

    .info__title {
      div {
        font-size: 0.75em;

        &:first-child {
          margin-bottom: 0.35em;
          font-size: 1.0625em;
          font-weight: var(--font-weight-semi-bold);
        }
      }
    }
  }

  .recipt__button {
    width: calc(100% - 2em);
    padding: 0 1em;
    max-width: 414px;
    input{
      width: 100%;
    }
  }

  .companies__info {
    width: 100%;
    margin: -0.5em 0 2em;
    display: flex;
    flex-direction: column;

    .companies__or {
      margin: 1.5em 0;
      font-size: 0.75em;
      text-align: center;
      color: var(--theme-sec-text);
    }

    .input__submit {
      margin: 0;
      input{
        width: calc(100% - 2em);
      }
    }
  }

  .button__newMag {
    align-self: center;
    width: calc(100% - 2em);
    border: 1px dashed var(--border-grey);
    border-radius: 5px;

    svg,
    .button__desc {
      margin: 1.1em 1.5em;
    }

    svg {
      margin-right: 0.5em;
    }

    .button__desc {
      margin-left: 0.5em;
      text-align: center;
      font-size: 0.875em;
      font-weight: 500;
    }

    @media screen and (orientation: landscape) {
      & {
        width: 60%;
      }
    }
  }

  .element__icon {
    width: 26px;
    height: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.875em;
    font-weight: var(--font-weight-semi-bold);
    color: white;
    background-color: var(--red);
    border-radius: 50%;
  }

  .popup__companies {
    position: relative;
    align-self: flex-start;
    width: 100%;

    h2 {
      text-align: center;
    }

    .companies__list {
      max-height: 60vh;
      overflow-y: auto;

      .companies__element.list__element.--big {
        width: 100%;
        border: 1px solid var(--theme-text);
      }
    }

    .companies__main {
      width: 100%;
      min-width: 200px;
      max-width: 900px;
    }

    .companies__button {
      margin: 0.5em;
      padding: 1em;
      text-align: center;
      background-color: var(--theme-text);
      border-radius: var(--default-radius);

      span {
        font-size: 0.875em;
        color: var(--theme-bg);
      }
    }
  }

  .companies__list {
    width: 100%;
    padding: 0 0 1em 0 !important;
    justify-content: center !important;

    .companies__element.list__element.--big {
      position: relative;
      width: calc(100% - 2em);
      height: auto;
      min-height: 120px;
      max-height: 160px;
      border: 1px solid transparent;

      .list__title {
        align-items: flex-start;

        span {
          display: block;
        }

        svg {
          flex-shrink: 0;
          margin: 1em 0 1em 1em;
        }
      }

      p,
      .element__text {
        font-size: 0.875em;
      }

      .element__edit {
        color: var(--red);
      }

      p {
        font-weight: var(--font-weight-semi-bold);
      }

      .skeleton__p {
        height: 1.5em;
      }

      .element__text {
        color: var(--theme-sec-text);
      }

      .skeleton__text {
        width: 60%;

        & > span {
          margin: 0.25em 0;
        }
      }

      .skeleton__icon {
        width: 26px;
        height: 26px;
      }
      &.--selected {
        border-color: var(--red);
        .checkCircle {
          background-color: var(--red);
        }
      }
    }
  }

  .checkCircle {
    position: absolute;
    top: 16px;
    right: 16px;
    width: 18px;
    height: 18px;
    border-radius: 100%;
    background-color: var(--theme-bg);
    .icon {
      width: 18px;
      height: 18px;
      -webkit-mask-size: 18px;
      mask-size: 18px;
      background-color: var(--theme-sec-bg);
    }
  }
}
