.discounts__popup {
  .popup__title:not(:first-of-type) {
    margin-top: 2em;
    margin-bottom: 1em;
  }

  &__section {
    padding: 1em;
    margin-bottom: 0.5em;
    display: flex;
    flex-direction: column;
    gap: 1em;
    background-color: var(--theme-bg);
    border-radius: var(--button-radius);

    &__header {
      font-size: 0.875rem;
      font-weight: 600;
    }

    &__content {
      font-size: 0.875rem;
      color: var(--theme-sec-text);
    }

    &__promotion {
      margin-bottom: 2.5em;
      height: 2em;
      width: 100%;
      border: 1px solid var(--red);
      border-radius: var(--button-radius);
      display: flex;
      align-items: center;
      justify-content: space-around;

      .promotion__line {
        position: relative;
        width: 100%;
        height: 100%;
        border-right: 1px solid var(--red);

        &.--selected {
          background-color: var(--red);
        }

        &:last-child {
          border-right: none;
        }

        .promotion__label {
          &.--selected span {
            font-weight: var(--font-weight-semi-bold);

            &:not(.--bottom) {
              color: white;
            }
          }

          span {
            font-size: 0.875em;
            font-weight: var(--font-weight-medium);

            &.--bottom {
              position: absolute;
              top: 3em;
              left: 50%;
              transform: translateX(-50%);
            }
          }
        }
      }
    }
  }

  button.--arrow {
    margin-top: 1em;
  }
}
