header {
  width: 100%;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  background-color: var(--theme-bg);
  box-sizing: border-box;
  z-index: 10;

  &.--ios > div {
    padding-top: 3em;
  }

  & > div {
    padding: 1em;
    &:not(:first-child) {
      padding-top: 0;
    }
  }

  &.--rounded {
    border-bottom-left-radius: var(--popup-radius);
    border-bottom-right-radius: var(--popup-radius);
  }

  &.--white {
    background-color: var(--theme-sec-bg);
  }

  .header__back {
    display: inline-flex;
  }

  .header__title {
    min-height: 1.7rem;
    font-size: 0.75rem;
    text-align: center;
    color: var(--theme-text);
    & > :nth-child(2) {
      margin-top: 0.2rem;
    }
  }

  .header__basketIcon {
    width: 36px;
    position: relative;
    display: grid;
    justify-content: end;
    .icon {
      background-color: var(--red);
    }
    &__amount {
      position: absolute;
      top: -2px;
      right: 0;
      width: 12px;
      height: 12px;
      font-size: 8px;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      background-color: var(--red);
      border-radius: 50%;
    }
    &__timer {
      text-align: center;
      font-size: 0.675rem;
      color: var(--theme-sec-text);
    }
  }

  &.--black {
    background-color: var(--black);
    .header__title {
      color: white;
    }
  }

  &.--flying {
    position: fixed;
    background: none;
  }

  .s36 {
    width: 36px;
    height: 36px;
  }

  .header__logo span {
    color: white;
    font-size: 0.75rem;
    font-weight: 600;
  }

  &.--black,
  &.--dark .header__back,
  &.--dark .header__logo {
    #logo path {
      fill: var(--border-grey);
    }

    #back path {
      fill: var(--border-grey);
      stroke: var(--border-grey);
    }
  }
}

.warning__popup {
  padding: 1em;
  position: fixed;
  width: 100vw;
  height: calc(100 * var(--vh, 1vh));
  background-color: var(--theme-bg);
  color: var(--theme-text);
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  text-align: center;
  z-index: 999;

  .warning__title {
    & > div:first-child {
      font-size: 1.6785em;
    }
  }

  .warning__title,
  .warning__desc {
    margin: 16% 0;
  }

  .warning__text {
    font-size: 1.25em;
  }

  .warning__time {
    margin: 0.2em 0;
    font-size: 5em;
  }

  .warning__circular {
    margin: 1em auto;
    height: 160px;
    width: 160px;
    position: relative;

    svg {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      -webkit-animation: circle 9s linear;
      animation: circle 9s linear;
      transform: translateY(-80px);
      z-index: 7;

      @-webkit-keyframes circle {
        0% {
          transform: rotate(0deg) translateY(-80px) rotate(0deg);
        }
        100% {
          transform: rotate(360deg) translateY(-80px) rotate(-360deg);
        }
      }

      @keyframes circle {
        0% {
          transform: rotate(0deg) translateY(-80px) rotate(0deg);
        }
        100% {
          transform: rotate(360deg) translateY(-80px) rotate(-360deg);
        }
      }
    }

    .circular__inner {
      position: absolute;
      margin: -78px 0 0 -78px;
      top: 50%;
      left: 50%;
      height: 156px;
      width: 156px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--theme-bg);
      border-radius: 100%;
      z-index: 6;
    }

    .circle__bar {
      position: absolute;
      height: 100%;
      width: 100%;
      border-radius: 100%;
      background-color: var(--border-grey);
      clip: rect(0px, 160px, 160px, 80px);

      .circle__progress {
        position: absolute;
        height: 100%;
        width: 100%;
        border-radius: 100%;
        background-color: var(--red);
        clip: rect(0px, 80px, 160px, 0px);
      }

      &.--left .circle__progress {
        -webkit-animation: left 4.5s linear both;
        animation: left 4.5s linear both;
        z-index: 1;
      }

      &.--right {
        transform: rotate(180deg);
        z-index: 3;

        .circle__progress {
          -webkit-animation: right 4.5s linear both 4.5s;
          animation: right 4.5s linear both 4.5s;
        }
      }
    }

    @-webkit-keyframes left {
      100% {
        transform: rotate(180deg);
      }
    }

    @keyframes left {
      100% {
        transform: rotate(180deg);
      }
    }

    @-webkit-keyframes right {
      100% {
        transform: rotate(180deg);
      }
    }

    @keyframes right {
      100% {
        transform: rotate(180deg);
      }
    }
  }
}
