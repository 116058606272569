.error {
  color: var(--theme-text);

  &.--centered {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
  }

  &.--static {
    padding: 1em;
    text-align: center;
  }
}