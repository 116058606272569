.popup__verification {
  padding: 1em;
  min-width: 300px;
  text-align: center;
  color: var(--theme-text);

  .verification__warning {
    color: var(--red);
  }

  .verefication__phone {
    color: var(--theme-text);
    margin: 2em 0;

    h2 {
      -webkit-margin-after: 0.4em;
          margin-block-end: 0.4em;
      letter-spacing: 2px;
    }
  }

  .input__default {
    margin: 1em 0.5em;
    width: calc(100% - 1em);

    .input__label {
      background-color: var(--theme-sec-bg);
    }
  }

  .verefication__button {
    font-size: 0.75em;
  }

  .input__submit {
    margin-top: 2em;
  }

  .verification__button {
    margin: 0.5rem;
    padding: 0.5rem 1rem;
    font-size: 0.875em;
    border: 1px solid var(--theme-text);
    border-radius: var(--button-radius);

    &.--primary {
      color: white;
      background-color: var(--red);
      border: none;
    }
  }

  .verification__code {
    margin-bottom: 0.75em;

    &.--warning input {
      color: var(--red);
      border-color: var(--red);
    }

    input {
      width: 3.2em;
      height: 4em;
      margin: 0 0.25em;
      padding: 0.4em;
      text-align: center;
      font-family: inherit;
      background: none;
      color: var(--theme-text);
      border: 1.5px solid var(--dark-grey);
      border-radius: var(--default-radius);
      box-sizing: border-box;

      &:valid,
      &:focus {
        border-width: 2px;
      }
    }
  }
}
