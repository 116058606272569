.app__login {
  width: 100vw;
  height: calc(100 * var(--vh, 1vh));
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  svg {
    width: 3em;
    height: 3em;
  }

  .login__main {
    width: calc(100% - 4em);
    text-align: center;
    margin: 4em 2em;

    .login__title {
      font-size: 1.2em;
    }

    .login__form {
      input[type="text"] {
        width: 100%;
        max-width: 500px;
        margin: 1em 0;
        padding: 0.7em;
        background-color: var(--light-grey);
        border-radius: var(--button-radius);
        box-sizing: border-box;
        outline: none;
        border: none;
      }

      input[type="checkbox"] {
        width: 1em;
        height: 1em;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        border: 1px solid var(--dark-grey);
        border-radius: 2px;
      }

      input:checked[type="checkbox"] {
        border-color: var(--black);
        background-color: var(--black);
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
      }

      .login__checkbox {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
