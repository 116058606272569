.orderInfo {
  .title {
    padding: 1em;
    span {
      font-size: 1.15rem;
    }

    // to remove
    .input__checkbox {
      font-size: 0.75rem;
      span {
        font-size: 0.75rem;
      }
    }
    // to remove
  }
  &__button {
    margin: 0 1em;
    button.--coupon {
      height: 50px;
      color: var(--theme-text);
    }
  }
  &__buttons {
    margin: 1em;
    display: flex;
    button.--back {
      flex-basis: 60%;
    }
  }
}
