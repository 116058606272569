.home {
  .title{
    padding: 1em 1.5em !important;
  }
  .list .list__element {
    &.--big .element__back {
      align-self: flex-end;
    }

    &.--black {
      .element__title {
        color: white;
      }

      svg path {
        fill: white;
      }
    }

    .element__group svg:first-child path {
      fill: var(--border-color);
    }

    .element__title {
      color: var(--theme-text);
      font-size: 0.8125em;
    }

    .element__text {
      color: var(--dark-grey);
      font-size: 0.75em;
    }
  }
}
