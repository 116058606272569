button.button {
  width: 100%;
  padding: 0.75em 0.4em;
  margin: 0.3em 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-radius: var(--button-radius);
  font-family: inherit;
  font-size: 1em;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background: none;
  border: none;

  &.--back span {
    color: var(--dark-grey);
    font-size: 0.875em;
  }

  &.--arrow {
    justify-content: center;
    color: var(--white);
    background-color: var(--red);

    &.--enlarged {
      padding: 1.1em 0.4em;

      span {
        font-size: 0.875em;
      }
    }

    & > * {
      margin: 0 0.2em;
    }

    span {
      font-size: 0.75em;
    }
  }

  &.--animate {
    -webkit-animation: bounce 200ms ease-out;
    animation: bounce 200ms ease-out;

    @-webkit-keyframes bounce {
      50% {
        transform: scale(1.05);
      }
    }

    @keyframes bounce {
      50% {
        transform: scale(1.05);
      }
    }
  }

  &.--disabled {
    background-color: var(--dark-grey);
    &.--back {
      opacity: 0.6;
      background: none;
    }
  }

  &.--black {
    color: var(--white);
    background-color: var(--black);
  }

  &.--gray {
    color: var(--black);
    background-color: var(--hr-color);
  }

  &.--coupon {
    color: var(--dark-grey);
    border: 1px dashed var(--dark-grey);

    span {
      font-size: 0.75em;
    }
  }
}

.button__circle {
  margin: 0.5em;
  display: flex;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &.--disabled {
    cursor: default;
    opacity: 0.5;
  }

  &.--big {
    width: 6.6em;
    height: 6.6em;
    color: white;
    position: relative;
    justify-content: center;
    background-color: var(--red);
    border: 9px solid var(--circle-border);
    border-radius: 50%;

    .button__ring {
      position: absolute;
      width: 7em;
      height: 7em;
      border-radius: 50%;
      border: 2px solid var(--red);

      &.--rotated {
        border-color: var(--red) transparent var(--red) transparent;
        -webkit-animation: ringRotate 2s linear infinite;
        animation: ringRotate 2s linear infinite;
      }

      @-webkit-keyframes ringRotate {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }

      @keyframes ringRotate {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }

    span {
      font-size: 0.875em;
      text-align: center;
    }
  }

  .button__icon {
    width: 4em;
    height: 4em;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--button-sec-bg);
    border-radius: 50%;
  }

  .button__text {
    margin-top: 0.5em;
    width: -webkit-min-content;
    width: -moz-min-content;
    width: min-content;
    text-align: center;
    font-size: 0.875em;
    color: var(--theme-text);
    font-weight: var(--font-weight-medium);
  }
}
