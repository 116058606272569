.splash {
  padding: 1em 0;
  height: calc(var(--vh, 1vh) * 100);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
  background-color: #141414;
  box-sizing: border-box;
  overflow: hidden;

  .splash__logo {
    flex-direction: column;

    svg {
      width: 2.5em;
      height: auto;
    }

    #logo path {
      fill: var(--border-grey);
    }

    span {
      margin-top: 1em;
      font-size: 0.75em;
      color: var(--border-grey);
    }
  }

  .splash__slides {
    --swiper-pagination-bullet-inactive-color: white;
    --swiper-pagination-color: var(--red);

    .slide__desc {
      color: white;
      font-size: 1.6em;
      min-height: 3em;
      font-weight: var(--font-weight-semi-bold);
      text-align: center;
    }
  }

  .splash__footer {
    width: 100%;
    flex-direction: column;
  }

  .splash__message {
    color: var(--dark-grey);
  }

  .splash__button {
    margin: 1em;
    width: calc(100% - 4em);
    max-width: 600px;

    .button.button.--arrow span {
      font-size: 1.125em;
    }
  }
}
