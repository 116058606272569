.billing,
.contract,
.registration,
.reset__password {
  .form__title {
    text-align: center;

    span {
      font-size: 0.75em;
    }
  }

  .main {
    flex-direction: column;
    position: relative;
    min-height: 200px;

    .form,
    .billing__menu {
      padding: 0 1.5em;
      width: calc(100% - 3em);
      max-width: 600px;
    }

    .form {
      .form__info {
        margin: 0.5em 0;
        display: flex;
        align-items: center;

        span {
          margin-left: 1em;
          font-size: 0.75em;
        }
      }

      .checkbox__area {
        width: auto;
        padding: 1em;
      }
    }
  }
}
