.app__mobile {
  overflow: hidden;
  @-webkit-keyframes slideLeft {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0);
    }
  }

  @keyframes slideLeft {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0);
    }
  }

  @-webkit-keyframes slideRight {
    0% {
      margin-left: 100%;
    }
    100% {
      margin-left: 0;
    }
  }

  @keyframes slideRight {
    0% {
      margin-left: 100%;
    }
    100% {
      margin-left: 0;
    }
  }

  &.--left main.--animated > *,
  &.--left main section.--animated > * {
    -webkit-animation: slideLeft 500ms ease-in-out;
    animation: slideLeft 500ms ease-in-out;
  }

  &.--right main.--animated > *,
  &.--right main section.--animated > * {
    -webkit-animation: slideRight 500ms ease-in-out;
    animation: slideRight 500ms ease-in-out;
  }

  main {
    position: relative;
    flex: 1;
    color: var(--theme-text);
    background-color: var(--theme-bg);
    overflow-y: auto;
  }

  main > * {
    width: 100%;
    height: 100%;
  }

  main > *,
  main section.--animated > * {
    min-width: 100%;
    box-sizing: border-box;
  }

  .app__container {
    position: relative;
    height: calc(var(--vh, 1vh) * 100);
    display: flex;
    justify-content: flex-end;
    background-color: var(--theme-sec-bg);

    .app__main {
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      transition: all 300ms;
      background-color: var(--theme-bg);

      &.--small {
        right: 220px;
        transform: scale(0.85);
        transform-origin: right;
        border-radius: var(--calendar-radius);
        box-shadow: 0 0 1em var(--boxshadow);
        overflow: hidden;

        &.--rotate {
          transform: perspective(1000px) rotate3d(0, 1, 0, 15deg) scale(0.85);
        }

        &::after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: 100;
        }
      }
    }
  }

  aside.app__menu {
    width: 200px;
    height: 100%;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;

    .menu__logo {
      padding: 2em;
      flex-direction: column;

      span {
        margin: 1em;
        font-size: 0.75em;
        color: var(--theme-text);
      }
    }

    .menu__nav {
      width: 100%;
      overflow-y: auto;
    }

    .menu__greeting {
      padding: 0.25em 1.25em;

      span {
        font-size: 1.25em;
        color: var(--theme-text);
        font-weight: 500;
      }
    }

    hr {
      width: 80%;
      border: solid var(--hr-color);
      border-width: 1px 0 0;
    }

    .nav__item {
      padding: 0.75em 1em;
      display: flex;
      align-items: center;
      color: var(--theme-text);
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      cursor: pointer;

      &.--red {
        .item__svg svg {
          path,
          line {
            stroke: var(--red);
          }
        }

        span {
          color: var(--red);
        }
      }

      .item__svg {
        width: 2.5em;
        display: flex;
        justify-content: center;
        margin-right: 1em;

        svg {
          line,
          rect {
            &:not(.notStroke) {
              stroke: var(--border-grey);
            }
          }
        }
      }
    }
  }

  .popup__mod {
    color: var(--theme-text);

    .mod__list {
      display: flex;
      align-items: center;
      flex-direction: column;

      label {
        padding: 1em;

        input[type="checkbox"] {
          width: 1em;
          height: 1em;
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
          border: 1px solid var(--dark-grey);
          border-radius: 2px;
        }

        input:checked[type="checkbox"] {
          border-color: var(--black);
          background-color: var(--black);
          background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
        }
      }
    }
  }

  .popup__newMag {
    width: 100%;
    max-width: 400px;
    color: var(--theme-text);

    h2,
    p {
      text-align: center;
    }

    .popup__header {
      margin: 0.5em 1em;
      font-weight: 500;
    }

    .popup__menu {
      margin: 0.75em 1em 1em;
      display: flex;
      justify-content: space-between;

      .menu__item {
        width: calc(50% - 0.5em);
        background-color: var(--theme-bg);
        border-radius: var(--default-radius);

        .menu__arrow {
          width: 1.6em;
          height: 1.6em;
          margin: 0.5em 0.5em 0.5em 0;
          background-color: var(--black);
          flex-shrink: 0;
          border-radius: 50%;
          align-self: flex-end;
        }

        .item__desc {
          padding: 1.5em 1em;
          font-size: 0.8125em;
        }
      }
    }
  }
}
