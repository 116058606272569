.actionsButton__buttons {
  margin: 0.5em 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  .buttons__col {
    flex-direction: column;
  }

  .buttons__row {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-basis: 50%;
    flex-grow: 1;

    hr {
      margin: 0;
      width: 1px;
      height: 3em;
      align-self: center;
      border-width: 0 1px 0 0;
    }
  }

  .menu__button {
    margin: 0.5em;
    height: 80px;
    width: 33%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;

    span {
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-size: 0.875em;
      word-break: break-word;
      font-weight: var(--font-weight-medium);
    }

    .icon{
      background-color: var(--red);
    }
  }

  hr {
    width: 100%;
    height: 1px;
    border-width: 0 0 1px 0;
    border-color: var(--additional-color);
  }
}