.input__default {
  position: relative;
  margin: 1em 0;
  width: 100%;
  border: 1px solid var(--theme-sec-text);
  border-radius: var(--default-radius);
  box-sizing: border-box;

  &.--focused {
    border-color: var(--theme-text);
  }

  &.--disabled {
    opacity: 0.5;
  }

  &.--warning {
    border-color: var(--red);

    label.input__label span {
      color: var(--red);
    }

    .input__icon.--left svg g > * {
      stroke: var(--red);
    }
  }

  label.input__label {
    position: absolute;
    top: -0.75em;
    left: 0.75rem;
    padding: 0 0.25rem;
    max-width: 80%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    background-color: var(--theme-bg);
    border-bottom-left-radius: var(--default-radius);
    border-bottom-right-radius: var(--default-radius);
    z-index: 1;

    span {
      font-size: 0.75em;
      color: var(--theme-text);
    }

    .label--warning {
      color: var(--red);
    }
  }

  .input__main {
    .input__icon {
      position: absolute;
      display: flex;
      top: 50%;
      transform: translateY(-50%);

      &.--left {
        left: 1em;
      }

      &.--right {
        right: 1em;
      }
    }

    .input,
    .select {
      position: relative;
      width: 100%;
      padding: 1rem;
      font-size: 0.875em;
      background: none;
      font-family: inherit;
      color: var(--theme-text);
      font-weight: var(--font-weight-semi-bold);
      box-sizing: border-box;
      border: none;
    }

    .input {
      &.--left {
        padding-left: 3.2em;
      }

      &.--right {
        padding-right: 3.2em;
      }

      &:-internal-autofill-selected {
        -webkit-text-fill-color: var(--theme-text);
        box-shadow: inset 0 0 0 1000px var(--theme-bg);
        border-radius: var(--default-radius);
      }
    }
  }
}

.input__checkbox {
  display: flex;
  align-items: center;

  input[type="checkbox"] {
    width: 1em;
    height: 1em;
    flex-shrink: 0;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    border: 1px solid var(--dark-grey);
    border-radius: 2px;
  }

  input:checked[type="checkbox"] {
    border-color: var(--black);
    background-color: var(--black);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
  }

  label {
    padding-left: 1em;
    font-size: 0.75em;
    color: var(--theme-text);

    span.--warning {
      color: var(--red);
    }
  }
}

.select__container {
  margin: 1em 0;
  position: relative;
  width: 100%;

  &.--secTheme {
    .input__default {
      background-color: var(--theme-sec-bg);
    }
  }

  &:has(.select) {
    .select__options {
      border-color: var(--theme-sec-text);
    }
  }

  &.--mobile .select__options {
    width: calc(100% - 2em - 2px);
  }

  .input__default {
    margin: 0;
    background-color: var(--theme-bg);
  }

  .select {
    display: flex;
    align-items: center;
    gap: 0.5em;
    span {
      flex: 1;
    }
  }

  &.--expanded {
    .input__default {
      z-index: 11;
    }

    .select__options {
      padding: 1em 1em 0;
      top: calc(100% - 1em);
      max-height: 150px;
      overflow-y: auto;
      border-width: 0 1px 1px;
      z-index: 10;
    }
  }

  .select__option {
    margin: 0.5em 0;
    padding: 0.5em 0;
    font-size: 0.875em;

    &s {
      padding: 0 1em;
      position: absolute;
      top: 100%;
      width: calc(100% - 2em - 1px);
      max-height: 0;
      overflow-y: hidden;
      background-color: var(--theme-sec-bg);
      border-bottom-left-radius: var(--default-radius);
      border-bottom-right-radius: var(--default-radius);
      border: 0 solid var(--theme-text);
      transition: 300ms max-height ease;
    }

    p {
      font-size: 0.75em;
    }
  }
}

.input__submit {
  position: relative;
  width: 100%;
  margin: 1em 0;

  .input__spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 28px;
    height: 28px;
    translate: -50% -50%;
    -webkit-animation: fadeIn 300ms ease-in;
    animation: fadeIn 300ms ease-in;
    z-index: 1;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 20px;
      height: 20px;
      border: 4px solid white;
      border-top-color: transparent;
      -webkit-animation: rotate 1s linear infinite;
      animation: rotate 1s linear infinite;
      border-radius: 50%;
    }
  }

  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0;
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
  }

  @-webkit-keyframes rotate {
    100% {
      rotate: 360deg;
    }
  }

  @keyframes rotate {
    100% {
      rotate: 360deg;
    }
  }

  &.--loading input {
    background-color: rgba(235, 0, 61, 0.75);
  }

  &.--disabled input {
    background-color: #3a3a3a;
  }

  input {
    color: white;
    min-width: 60%;
    font-size: 0.875em;
    padding: 0.9628rem;
    text-align: center;
    background-color: var(--red);
    border-radius: var(--button-radius);
    border: none;
  }
}

.switch {
  input[type="checkbox"] {
    display: none;
  }

  label {
    cursor: pointer;
    text-indent: -9999px;
    width: 36px;
    height: 20px;
    background: var(--dark-grey);
    display: block;
    border-radius: 20px;
    position: relative;

    &:after {
      content: "";
      position: absolute;
      top: 3px;
      left: 3px;
      width: 14px;
      height: 14px;
      background: var(--light-grey);
      border-radius: 14px;
      transition: 0.3s;
    }

    &:active:after {
      width: 20px;
    }
  }

  input:checked + label {
    background: var(--red);
  }

  input:checked + label:after {
    left: calc(100% - 3px);
    transform: translateX(-100%);
  }
}
