.summary {
  padding: 1em;
  padding-top: 0;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  gap: 1em;
  > div {
    .list__element {
      padding: 1em;
      border-radius: 10px;
      background-color: var(--theme-sec-bg);
      font-size: 0.785rem;
      color: var(--theme-sec-text);
      width: calc(100% - 2em);
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    .element__desc {
      line-height: 1.5;
    }

    .element__title {
      &:not(:first-of-type) {
        margin-top: 0.5em;
      }
      font-size: 0.85rem;
      font-weight: 600;
      color: var(--theme-text);
      display: flex;
      gap: 10px;
    }

    .list__header {
      margin: 0.5em 0;
    }
  }

  .link {
    text-decoration: underline;
  }

  .--marked {
    color: var(--theme-text);
    font-weight: var(--font-weight-semi-bold);
  }

  .orderTitle {
    padding: 1em 0 !important;
    background-color: unset;
  }

  // .list {
  //   align-items: stretch !important;
  //   padding: 1em !important;

  //   .list {
  //     flex-basis: 100%;
  //     margin: 0;
  //     padding: 0;
  //     width: 100%;
  //   }

  //   .element__title {
  //     margin-left: 0;
  //     font-size: 0.875em;
  //     font-weight: var(--font-weight-semi-bold);
  //   }

  //   .list__element.--big {
  //     height: calc(100% - 2.875em);
  //     min-height: auto;
  //     max-height: none;
  //     padding: 0.5em 1em;
  //     justify-content: center;

  //     & > div {
  //       width: 100%;
  //       padding: .5em;
  //     }

  //     .element__title {
  //       span {
  //         margin-right: 1em;
  //         display: inline-block;
  //       }
  //     }

  //     .group > div:first-child {
  //       margin-right: 1em;
  //     }

  //     .element__group {
  //       align-items: flex-end;
  //     }

  //     .element__desc {
  //       color: var(--dark-grey);
  //       font-size: 0.875em;

  //       .--marked {
  //         color: var(--theme-text);
  //         font-weight: var(--font-weight-semi-bold);
  //       }
  //     }

  //     .skeleton__desc {
  //       width: 60%;
  //     }
  //   }

  //   .list__element.--big, .list__element{
  //     aspect-ratio: unset !important;
  //   }
  // }

  .summary__buttons {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-bottom: 1em;
    & > * {
      max-width: 414px;
    }
  }
}
