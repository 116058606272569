:root {
  --red: #eb003d;
  --orange: #ff9100;
  --green: #00eb85;
  --white: #ffffff;
  --black: #181818;
  --dark-grey: #7c7c7c;
  --light-grey: #f6f6f7;
  --border-grey: #d8d8d8;
  --hr-color: #e9e9e9;
  --toast-bg: rgba(0, 0, 0, 0.75);
  --boxshadow: rgba(70, 70, 70, 0.3);
  --shadow: rgba(70, 70, 70, 0.05);

  --font-weight-light: 300;
  --font-weight-medium: 500;
  --font-weight-semi-bold: 600;
  --font-weight-heavy: 800;

  --menu-radius: 85px;
  --popup-radius: 40px;
  --calendar-radius: 20px;
  --default-radius: 10px;
  --button-radius: 5px;
}

@font-face {
  font-family: "termina";
  src: url("./assets/fonts/Termina/Termina-Demi.woff2") format("woff2"),
    url("./assets/fonts/Termina/Termina-Demi.woff") format("woff");
}

/* @font-face {
  font-family: "montserrat";
  src: url("./assets/fonts/Montserrat/Montserrat-VariableFontTest.otf") format("opentype");
} */

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  font-family: montserrat, sans-serif;
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  font-size: 14px;
}

body svg {
  overflow: unset;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media screen and (max-width: 400px) and (max-height: 600px) {
  body {
    font-size: 12px;
  }
}
   
.--termina {
  font-family: termina, sans-serif;
}

.--sb {
  font-weight: var(--font-weight-semi-bold);
}

*:focus {
  outline: none;
}

input {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  font-size: inherit;
}
