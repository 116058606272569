.bottomBar {
  height: 64px;
  width: 100%;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  justify-items: center;
  justify-content: start;

  background-color: var(--theme-sec-bg);
  border-top: 1px solid var(--bottombar-border);

  &__button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;

    padding-top: 8px;
    transform: translateY(-1.2px);

    span {
      font-size: 9px;
      text-transform: uppercase;
      font-weight: 600;
      color: var(--gray-50);
      padding-top: 6px;
    }
    .icon {
      background-color: var(--gray-50);
    }

    &.active {
      border-top: 2px solid var(--theme-text);
      span {
        color: var(--theme-text);
      }
      .icon {
        background-color: var(--theme-text);
      }
    }
  }

  &.gray{
    background-color: var(--theme-bg);
  }
  &.ios{
    height: 80px;
  }
}
