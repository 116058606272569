@use "sass:math";

.newWarehouse {
  &,
  .slides {
    min-height: inherit;
  }

  .slides {
    height: 100%;
    overflow: scroll;
    position: relative;
    padding: 1em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    box-sizing: border-box;
    z-index: 1;

    & > div {
      margin: 1.5em 0;

      &:first-child,
      &:last-child {
        margin: 0;
      }
    }

    .slide__address {
      margin-top: 0.5em;
      font-size: 0.875em;
    }

    .slide__title {
      flex-direction: column;
      text-align: center;

      div:first-child {
        font-size: 1.25em;
        font-weight: var(--font-weight-semi-bold);
        margin-bottom: 0.5em;
      }
    }

    .slide__desc {
      font-size: 0.875em;
      text-align: center;
    }

    .slide__warehouses,
    .slide__gate {
      position: relative;
      z-index: 1;

      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 0;
        height: 0;
        box-shadow: 0 0 5em 3em white;
        z-index: -2;
      }
    }

    .fireworks {
      margin: 0;
      left: 10%;
      top: 10%;
      width: 80%;
      height: 80%;
    }

    .slide__warehouses {
      height: 154px;

      &.--wider .warehouse {
        &.--left {
          opacity: 0.75;
          transform: translate(-20%, -50%) scale(0.5);
        }

        &.--right {
          opacity: 0.75;
          transform: translate(-80%, -50%) scale(0.5);
        }
      }

      .img--phone {
        height: 90%;
        width: auto;
      }

      .warehouse {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(0.6);

        &.--left {
          opacity: 0.9;
          transform: translate(-30%, -50%) scale(0.5);
          z-index: -1;
        }

        &.--right {
          opacity: 0.9;
          transform: translate(-70%, -50%) scale(0.5);
          z-index: -1;
        }
      }
    }

    .slide__buttons {
      width: 100%;
      display: flex;
      align-items: center;

      & > * {
        margin: 0 1em;
      }

      button {
        flex-basis: 60%;
      }

      .input__submit input {
        width: 100%;
      }
    }

    .slide__photos {
      display: flex;
      align-items: center;
      flex-direction: column;

      label {
        padding: 1em;
        font-weight: var(--font-weight-semi-bold);
      }

      .photos__slides {
        max-width: calc(100vw - 6em);
        overflow-x: auto;
        display: flex;

        .slides__image {
          padding: 0 0.5em;
          position: relative;

          img {
            max-width: 250px;
            max-height: 250px;
            border-radius: var(--button-radius);
          }

          .image__close {
            position: absolute;
            top: 1em;
            right: 1em;
            width: 2em;
            height: 2em;
            background-color: var(--theme-sec-bg);
            border-radius: 50%;

            &::after,
            &::before {
              content: "";
              position: absolute;
              top: 0.5em;
              left: 0.9em;
              width: 0.2em;
              height: 1em;
              background-color: var(--theme-text);
              border-radius: 2px;
            }
            &::before {
              transform: rotate(-45deg);
            }
            &::after {
              transform: rotate(45deg);
            }
          }
        }
      }
    }
    .input__submit {
      input {
        width: 100%;
      }
    }
  }
}

.detailsSlide {
  min-height: calc((var(--vh, 1vh) * 100) - 2em);
  width: 100%;
  display: flex;
  flex-direction: column;
  &__head {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .slide__address {
      font-size: 0.785rem;
      font-family: "termina";
    }
  }
  &__body {
    flex-grow: 1;
    &__img {
      padding: 2em 0;
      width: 100%;
      img {
        width: 100%;
        height: auto;
      }
    }
    .section {
      &__title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        h2 {
          font-size: 1.15em;
          font-weight: 600;
          margin: 0;
          padding: 1em 0;
        }
      }
      &__content {
        display: flex;
        gap: 10px;
        p {
          margin: 0;
          flex-basis: 100%;
          background-color: var(--theme-sec-bg);
          padding: 1em;
          border-radius: 10px;
          font-size: 0.785rem;
          color: var(--theme-sec-text);
          font-weight: 500;
          &.phone {
            font-weight: 600;
            color: var(--theme-text);
          }
        }
        .rec {
          flex-grow: 1;
          background-color: var(--theme-sec-bg);
          min-height: 110px;
          border-radius: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-family: "termina";
          font-size: 1.5rem;
        }
      }
      button {
        margin: 1em 0;
        width: 100%;
        background-color: transparent;
        border: 1px dashed var(--border-grey);
        color: var(--theme-sec-text);
        font-family: "montserrat";
        font-weight: 500;
        font-size: 0.875em;
        height: 45px;
        border-radius: 5px;
      }
    }
  }
  &__footer {
    padding-top: 1em;
    display: flex;
    .--back {
      flex-basis: 50%;
    }
  }
}

.fireworks {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;

  $particles: 50;
  $width: 250;
  $height: 250;
  $box-shadow: ();
  $box-shadow2: ();

  @for $i from 0 through $particles {
    $box-shadow: $box-shadow,
      random($width) -
        math.div($width, 2) +
        px
        random($height) -
        math.div($height, 1.2) +
        px
        hsl(random(360), 100%, 50%);
    $box-shadow2: $box-shadow2, 0 0 #fff;
  }

  @mixin keyframes($animationName) {
    @-webkit-keyframes #{$animationName} {
      @content;
    }

    @keyframes #{$animationName} {
      @content;
    }
  }

  @mixin animation-delay($settings) {
    -webkit-animation-delay: $settings;
    animation-delay: $settings;
  }

  @mixin animation-duration($settings) {
    -webkit-animation-duration: $settings;
    animation-duration: $settings;
  }

  @mixin animation($settings) {
    -webkit-animation: $settings;
    animation: $settings;
  }

  @mixin transform($settings) {
    transform: $settings;
    -moz-transform: $settings;
    -webkit-transform: $settings;
    -o-transform: $settings;
    -ms-transform: $settings;
  }

  & > div {
    position: absolute;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    box-shadow: $box-shadow2;
    @include animation(
      (
        1s bang ease-out infinite backwards,
        1s gravity ease-in infinite backwards,
        5s position linear infinite backwards
      )
    );

    &:nth-child(2n) {
      @include animation-delay((1.25s, 1.25s, 1.25s));
      @include animation-duration((1.25s, 1.25s, 6.25s));
    }
  }

  @include keyframes(bang) {
    0% {
      opacity: 0;
    }
    5% {
      opacity: 1;
    }
    100% {
      box-shadow: $box-shadow;
    }
  }

  @include keyframes(gravity) {
    to {
      @include transform(translateY(200px));
      opacity: 0;
    }
  }

  @include keyframes(position) {
    0%,
    19.9% {
      margin-top: 10%;
      margin-left: 40%;
    }
    20%,
    39.9% {
      margin-top: 40%;
      margin-left: 30%;
    }
    40%,
    59.9% {
      margin-top: 20%;
      margin-left: 70%;
    }
    60%,
    79.9% {
      margin-top: 30%;
      margin-left: 20%;
    }
    80%,
    99.9% {
      margin-top: 30%;
      margin-left: 80%;
    }
  }
}
