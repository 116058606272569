.option {
  position: relative;
  padding: 1em;
  margin: 1em 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--theme-sec-bg);
  border: 1px solid var(--theme-sec-bg);
  border-radius: var(--default-radius);

  &.--selected {
    border-color: var(--red);

    .option__button {
      color: white;
      background-color: var(--red);
    }

    .option__label span {
      color: var(--red);
    }

    .option__icon:not(.--color) svg {
      rect,
      line {
        stroke: var(--red);
      }

      path {
        fill: var(--red);
      }
    }

    &.--secondary {
      border-color: var(--black);

      .option__button {
        background-color: var(--black);
      }

      .option__label span {
        color: var(--theme-text);
      }

      .option__icon:not(.--color) svg {
        rect,
        line {
          stroke: var(--theme-text);
        }

        path {
          fill: var(--theme-text);
        }
      }
    }
  }

  .option__label {
    position: absolute;
    padding: 0 0.5em;
    left: 1em;
    top: -0.75em;
    font-size: 0.75em;
    background-color: inherit;

    span {
      padding: 0.25em 0.5em;
      border-radius: var(--button-radius);
    }
  }

  .option__icon {
    width: 47px;
    height: 47px;
    background-color: var(--additional-color);
    border-radius: var(--button-radius);
    flex-shrink: 0;

    svg {
      max-width: 24px;

      rect,
      line {
        stroke-width: 1.5;
      }
    }
  }

  .option__title {
    text-align: left;
    margin: 0 1em;

    div:first-child {
      color: var(--theme-text);
      font-size: 0.8125em;
      margin-bottom: 0.4em;
    }

    div {
      color: var(--dark-grey);
      font-size: 0.75em;
      word-break: break-word;
    }
  }

  .option__button {
    min-width: 18%;
    padding: 1rem;
    font-size: 0.75em;
    text-align: center;
    border-radius: var(--button-radius);
    background-color: var(--theme-bg);
  }
}
