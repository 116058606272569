.popup__bg {
  position: fixed;
  bottom: 0;
  left: 0;
  margin: 0;
  width: 100vw;
  height: calc(100 * var(--vh, 1vh));
  display: flex;
  align-items: flex-end;
  background-color: var(--toast-bg);
  -webkit-animation: darken 200ms ease-in;
  animation: darken 200ms ease-in;
  z-index: 1000;

  &.--centered {
    align-items: center;
    justify-content: center;

    .popup {
      margin: 0 1.5em;
      padding: 1.5em;
      max-width: 700px;
      width: calc(100% - 3em);
      border-radius: var(--calendar-radius);
    }
  }

  &.--close {
    -webkit-animation-name: lighten;
    animation-name: lighten;

    .popup {
      -webkit-animation-name: moveOut;
      animation-name: moveOut;
      -webkit-animation-duration: 200ms;
      animation-duration: 200ms;
    }
  }

  .popup {
    padding: 1em;
    width: 100%;
    max-height: calc(var(--vh, 1vh) * 90);
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: var(--theme-sec-bg);
    border-top-left-radius: var(--popup-radius);
    border-top-right-radius: var(--popup-radius);
    -webkit-animation: moveIn 300ms ease-out;
    animation: moveIn 300ms ease-out;
    box-sizing: border-box;
    overflow-y: auto;

    .popup__line {
      padding: 1em 0;
      position: -webkit-sticky;
      position: sticky;
      width: 100%;
      top: 0;
      display: flex;
      justify-content: center;
      background-color: var(--theme-sec-bg);
      z-index: 5;

      &::after {
        content: "";
        width: 20%;
        max-width: 80px;
        height: 4px;
        background-color: #dedee2;
        border-radius: var(--button-radius);
      }
    }

    .popup__title {
      width: 100%;
      position: relative;
      margin-bottom: 2em;
      margin-top: 0;
      text-align: center;

      svg {
        margin: 1em 0;
      }

      .icon--back {
        position: absolute;
        top: calc(50% + 2px);
        transform: translateY(-50%);
        left: 0;
      }

      &.--sticky {
        position: -webkit-sticky;
        position: sticky;
        margin: 0;
        width: 100%;
        padding: 2em 0.5em;
        top: calc(2em + 4px);
        background-color: var(--theme-sec-bg);
        z-index: 2;
      }

      & > div {
        &:first-of-type {
          color: var(--theme-text);
          font-size: 1.125rem;
          font-weight: var(--font-weight-semi-bold);
        }

        &:nth-of-type(2) {
          -webkit-margin-before: 0.5em;
          margin-block-start: 0.5em;
          color: var(--dark-grey);
        }
      }
    }

    .popup__list {
      margin: 0 2em 2em;

      .list__title {
        margin-top: 1rem;
        color: var(--theme-text);
        font-size: 0.875em;
        font-weight: var(--font-weight-semi-bold);
      }

      ul {
        -webkit-padding-start: 20px;
        padding-inline-start: 20px;

        li {
          margin: 0.5em 0;
          color: var(--dark-grey);
          font-size: 0.875em;
        }
      }
    }

    .serviceCall__popup {
      width: 100%;
      max-width: 600px;

      svg {
        margin: 1rem 0;
      }
    }

    .popup__basket--warning,
    .serviceCall__popup {
      h3,
      p {
        color: var(--theme-text);
        text-align: center;
      }

      p {
        -webkit-margin-after: 2em;
        margin-block-end: 2em;
      }

      .button.--cancel {
        color: var(--theme-text);
        background-color: var(--additional-color);
      }

      .button.--accept {
        color: white;
        background-color: var(--red);
      }

      .button {
        padding: 0.6rem;
        width: 50%;
        font-size: 0.875em;
        text-align: center;
        font-weight: var(--font-weight-semi-bold);
        border-radius: var(--button-radius);
      }

      .popup__buttons {
        margin: 0.75em 0;
        gap: 1em;
      }
    }

    &.darkBg {
      background-color: var(--theme-bg);
      .popup__line {
        background-color: var(--theme-bg);
      }
    }
  }
}

.popup__form {
  width: 100%;
}

.galleryPopup {
  position: fixed;
  bottom: 0;
  left: 0;
  margin: 0;
  width: 100vw;
  height: calc(100 * var(--vh, 1vh));
  background-color: var(--black);
  color: white;
  z-index: 1000;
  &__content {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 1em;
    &__head {
      position: fixed;
      top: 1em;
      padding: 0 1em;
      text-align: center;
    }
    &__body {
      flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &__footer {
      height: 100px;
    }
  }

  .swiper-slide img {
    height: 100%;
    object-fit: contain;
    width: 100%;
  }
  .thumbs__swiper .swiper-slide {
    height: 100%;
    opacity: 0.4;
    width: 50px;
  }
  .thumbs__swiper .swiper-slide-thumb-active {
    opacity: 1;
  }
  .swiper-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .swiper-pagination-fraction {
    position: fixed;
    bottom: 2em;
  }
  .image-gallery-thumbnails {
    width: calc(100% - 2em);
    padding: 0 1em;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 2em;
  }
}
