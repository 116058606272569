.help {
  .help__main {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    flex-direction: column;

    .help__header {
      text-align: center;

      @media screen and (max-height: 560px) {
        padding: 2vmax 1.5em 1vmax;
      }
    }

    svg {
      margin: 1em;
      height: 20vh;
      max-height: 240px;
    }
  }

  h4 {
    text-align: start;
    margin: 1em;
  }

  .help__menu {
    position: absolute;
    left: 0;
    right: 0;
    top: calc(100 * var(--vh, 1vh) - 22.86em - 78px);
    height: 100%;
    display: flex;
    justify-content: center;
    background-color: var(--theme-sec-bg);
    border-top-left-radius: var(--menu-radius);
    border-top-right-radius: var(--menu-radius);
    transition: top 500ms;
    z-index: 12;

    & > div {
      max-width: 900px;
      display: flex;
      flex-direction: column;
      border-top-left-radius: var(--menu-radius);
      border-top-right-radius: var(--menu-radius);
    }

    .menu__header {
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      background-color: var(--theme-sec-bg);
      z-index: 1;

      &::after {
        content: "";
        position: absolute;
        width: 80%;
        height: 0;
        left: 10%;
        background-color: var(--theme-sec-bg);
        box-shadow: 0 0 0.8em 0.8em var(--theme-sec-bg);
      }
    }

    .menu__buttons {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-wrap: wrap;

      & > .button__circle:not(.--big) {
        width: 24vmin;
        margin-top: 4em;
      }

      .button__circle.--big {
        svg g {
          path {
            stroke: white;
          }

          circle {
            fill: white;
          }
        }

        span {
          margin: 0.5em 1em;
          font-size: 0.75em;
        }
        .center {
          flex-direction: column;
        }
      }
    }

    .help__notification {
      margin: 1em 0;
    }

    .help__faq .faq__list {
      margin: 0 1em 2em;

      .list__item {
        margin: 0.5em 0;

        p {
          margin-right: 1em;
        }

        i {
          color: var(--theme-sec-text);
        }
      }

      .item__arrow {
        position: relative;
        flex-shrink: 0;
        width: 1.4em;
        height: 1.4em;

        &::before,
        &::after {
          content: "";
          position: absolute;
          top: 30%;
          height: 70%;
          width: 1px;
          border-radius: 5px;
          background-color: var(--theme-sec-text);
          transition: all 100ms;
        }

        &::before {
          left: calc(30% - 1.5px);
          transform: translateX(50%) rotate(-45deg);
        }

        &::after {
          right: calc(30% - 1.5px);
          transform: translateX(-50%) rotate(45deg);
        }

        &.--down {
          &::before {
            transform: rotate(45deg);
          }

          &::after {
            transform: rotate(-45deg);
          }
        }
      }
    }
  }
}
