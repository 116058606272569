.popup__delivery {
  width: 100%;
  h2 {
    font-size: 1em;
    margin: 0;
    margin-top: 1em;
    font-weight: 600;
  }
  &__additionalText {
    background-color: var(--theme-sec-bg);
    padding: 1em;
    border-radius: 10px;
    margin-bottom: 1em;
  }
  &__product {
    h2 {
      font-size: 1em;
      margin: 0;
      font-weight: 600;
    }
    &__item {
      margin-top: 1em;
      position: relative;
      border: 1px solid transparent;
      background-color: var(--theme-sec-bg);
      border-radius: 10px;
      padding: 1em;
      h2 {
        font-size: 1em;
        margin: 0;
        font-weight: 600;
      }
      span {
        font-size: 0.875em;
        color: var(--dark-grey);
      }
      .checkCircle {
        position: absolute;
        top: 50%;
        right: 1em;
        transform: translateY(-50%);
      }
      &.checked {
        border: 1px solid var(--red);
        .checkCircle {
          background-color: var(--red);
        }
      }
    }
  }
  &__saved {
    &__item {
      margin-top: 1em;
      position: relative;
      border: 1px solid transparent;
      background-color: var(--theme-sec-bg);
      border-radius: 10px;
      padding: 1em;

      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 0.5rem;

      &:first-of-type {
        margin-top: 2em;
      }

      &__actions {
        display: flex;
        align-items: center;
        gap: 1rem;
      }

      h2 {
        flex-grow: 1;
        margin: 0;
        font-size: 1em;
      }
      p {
        margin-bottom: 0;
      }
      .priceWrap {
        position: absolute;
        top: -12px;
        left: 50%;
        transform: translateX(-50%);
        background-color: var(--green);
        padding: 4px 12px;
        border-radius: 5px;
        color: white;
        font-size: 0.875em;
        font-weight: 600;
      }
      &.checked {
        border: 1px solid var(--red);
        .checkCircle {
          background-color: var(--red);
        }
      }
    }
  }
  &__options {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    padding: 1em 0;
    .selectItem {
      border: 1px solid transparent;
      position: relative;
      background-color: var(--theme-sec-bg);
      border-radius: 10px;
      padding: 1em;
      height: 45px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      h2 {
        font-size: 1em;
        margin: 0;
        font-weight: 600;
      }

      span {
        font-size: 0.75em;
        color: var(--dark-grey);
        margin: 0;
      }
      .checkCircle {
        position: absolute;
        top: 16px;
        right: 16px;
      }

      &.checked {
        border: 1px solid var(--red);
        .checkCircle {
          background-color: var(--red);
        }
      }
    }
  }
  .checkCircle {
    width: 18px;
    height: 18px;
    border-radius: 100%;
    background-color: var(--theme-bg);
    .icon {
      width: 18px;
      height: 18px;
      -webkit-mask-size: 18px;
      mask-size: 18px;
      background-color: var(--theme-sec-bg);
    }
  }
  .btn {
    margin: 1em 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px dashed var(--dark-grey);
    border-radius: 10px;
    height: 40px;
    font-weight: 600;
  }
  .selectItem {
    max-height: 45px;
    gap: 4px;
    h2 {
      width: calc(100% - 18px);
    }
  }
}
