.maps {
  width: 100%;
  height: 100%;
  position: relative;

  .map__point .point__title {
    margin-left: 1em;

    div:first-child {
      color: var(--theme-text);
      font-size: 0.875em;
      font-weight: var(--font-weight-semi-bold);
    }

    div {
      color: var(--dark-grey);
      font-size: 0.75em;
    }
  }

  .maps__main {
    position: relative;
    height: calc(var(--vh, 1vh) * 100 - 19.5em);
    transition: height 300ms;
    display: flex;
    align-items: center;
    overflow: hidden;

    &.--full {
      height: calc(98 * var(--vh, 1vh));
    }

    &.--wider {
      height: calc(var(--vh, 1vh) * 100 - 15.5em);
    }

    .map {
      width: 100%;
      height: calc(98 * var(--vh, 1vh));
      position: static !important;

      .leaflet-pane.leaflet-map-pane {
        position: relative;
      }

      .leaflet-pane {
        z-index: 1;
      }

      .leaflet-top,
      .leaflet-control {
        z-index: 10;
      }

      .leaflet-tooltip {
        padding: 0;
        height: 110px;
        width: 60px;
        color: var(--theme-text);
        font-family: montserrat, sans-serif;
        text-align: center;
        opacity: 1 !important;
        background: none;
        box-shadow: none;
        border: none;

        .marker__inner {
          &.--dark {
            color: #dedee2;
          }

          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: space-evenly;
        }

        .top {
          white-space: initial;
          font-size: 9px;
        }

        .bottom {
          font-size: 9px;
          font-weight: var(--font-weight-semi-bold);
        }

        &::before {
          display: none;
        }
      }

      .leaflet-top.leaflet-right {
        margin: 3.25em 0 2em;
        padding: 0 0.5em;
        right: 50%;
        transform: translateX(50%);
        white-space: nowrap;
      }

      .marker-cluster-small,
      .marker-cluster-small div {
        color: white;
        background-color: rgba(#eb003d, 0.4);
      }

      .marker-cluster-medium,
      .marker-cluster-medium div {
        color: white;
        background-color: rgba(#eb003d, 0.6);
      }

      .marker-cluster-large,
      .marker-cluster-large div {
        color: white;
        background-color: rgba(#eb003d, 0.8);
      }
    }

    .map__options {
      position: absolute;
      right: 1em;
      bottom: 3em;
      z-index: 11;

      & > * {
        margin: 0.5em;
        width: 2.6em;
        height: 2.6em;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--theme-bg);
        border-radius: var(--button-radius);
      }

      .location__button {
        display: flex;
      }

      svg {
        width: 1em;
        height: 1em;
      }

      .--active svg path {
        stroke: var(--red);
      }
    }
  }

  .map__menu {
    width: 100%;
    margin-top: -2em;
    position: relative;
    max-height: calc(100 * var(--vh, 1vh) - 1.75em);
    background-color: var(--black);
    background: linear-gradient(var(--black) 60%, var(--theme-bg) 40%);
    border-top-left-radius: var(--popup-radius);
    border-top-right-radius: var(--popup-radius);
    z-index: 22;

    &.--expanded {
      max-height: none;
      height: calc(100 * var(--vh, 1vh));
      .map__result {
        height: inherit;
        .result__header {
          height: inherit;
          .header__main {
            height: inherit;
            border-radius: 0;
          }
        }
      }
      .placeElement__bottomBar {
        display: flex;
      }
    }

    &.--mobile {
      margin: 0;
      position: absolute;
      left: 0;
      transition: top 500ms;
    }

    .menu__close {
      width: 100%;
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      left: 0;
      right: 0;
      background-color: var(--black);
      border-top-left-radius: var(--popup-radius);
      border-top-right-radius: var(--popup-radius);
      z-index: 24;

      div {
        padding: 1em;
        margin: 0 24%;

        &.--expanded svg {
          transform: rotate(180deg);
          transform-origin: 50% 50%;
        }

        svg {
          width: 1em;
          margin: 0 0.5em;
        }

        span {
          color: white;
          font-size: 0.75em;
        }
      }
    }

    .map__filter {
      padding: 0 1.5em 1.5em;
      height: 120px;
      display: flex;
      align-items: center;
      flex-direction: column;
      transition: height 300ms, opacity 200ms;
      opacity: 1;

      &.--smaller {
        height: 60px;
      }

      &.--hidden {
        padding: 0;
        height: 0;
        width: 90%;
        opacity: 0;
      }

      .filter__location {
        margin-right: 1em;
      }

      .filter__input {
        position: relative;
        height: 44px;
        width: 100%;

        input {
          width: 100%;
          height: 100%;
          padding: 0.75em 3.5em 0.75em 0.75em;
          background-color: #212121;
          color: white;
          border: none;
          border-radius: var(--button-radius);
          box-sizing: border-box;
        }

        svg {
          position: absolute;
          top: 50%;
          right: 1em;
          transform: translateY(-50%);
        }
      }

      .filter__group {
        width: 100%;
        padding: 0.5em 0;
      }

      @media (orientation: landscape) {
        & {
          height: 60px;
          flex-direction: row;

          & > * {
            margin: 0 2em;
          }
        }
      }
    }

    .map__result {
      width: 100%;
      position: relative;
      background-color: var(--theme-bg);

      &.--full {
        min-height: calc(var(--vh, 1vh) * 100 - 4.75em);
      }

      &.--animate {
        -webkit-animation: shake 1500ms infinite alternate;
        animation: shake 1500ms infinite alternate;
      }

      @-webkit-keyframes shake {
        0% {
          margin-top: 0;
        }
        50% {
          margin-top: -1em;
        }
        100% {
          margin-top: 0;
        }
      }

      @keyframes shake {
        0% {
          margin-top: 0;
        }
        50% {
          margin-top: -1em;
        }
        100% {
          margin-top: 0;
        }
      }

      .result__header {
        position: -webkit-sticky;
        position: sticky;
        top: 2.85em;
        left: 0;
        right: 0;
        background: linear-gradient(var(--black) 80%, var(--theme-bg) 20%);
        z-index: 23;

        .header__main {
          background-color: var(--theme-bg);
          border-top-left-radius: var(--popup-radius);
          border-top-right-radius: var(--popup-radius);

          & > * {
            padding: 1.5em 1.5em;
          }

          .header__title {
            width: 60%;
            margin-right: 2em;
            font-size: 0.875em;
            font-weight: 500;
          }
        }
      }

      .result__filter,
      .result__sort {
        display: flex;
        align-items: center;

        div {
          font-size: 0.875em;
        }

        svg {
          padding-right: 0.5em;
        }
      }

      .filters__list {
        padding: 0.5em 1em;
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        left: 0;
        right: 0;
        display: flex;
        overflow-x: auto;
        background-color: var(--theme-bg);
        transition: all 300ms;
        z-index: 22;

        &.--show {
          top: 7.2em;
        }

        .filters__item {
          margin-right: 0.5rem;
          font-size: 0.75em;
          display: flex;
          flex-direction: column;
          justify-content: center;
          border: 1px solid var(--theme-text);
          border-radius: var(--button-radius);
          white-space: nowrap;
          z-index: 1;

          .item__label {
            margin: -0.5em 1.5em -0.5em 0.1em;
            padding: 0 0.5em;
            align-self: flex-start;
            background-color: var(--theme-bg);
          }

          .item__content span {
            padding: 1em;
          }
        }
      }

      .filters__location {
        margin: 0.5em 1em 1em;
        padding: 1em;
        border: 1px solid var(--theme-text);
        border-radius: var(--button-radius);

        .location__desc {
          display: flex;
          align-items: center;

          .location__title {
            margin: 0 1em;
            font-size: 0.8125em;
          }
        }

        .location__button {
          padding: 0.5rem;
          color: var(--theme-bg);
          font-size: 0.75em;
          display: flex;
          align-items: center;
          background-color: var(--theme-text);
          border-radius: var(--button-radius);
        }
      }

      .result__start {
        position: absolute;
        width: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        .start__tile {
          margin: 3em;
          flex-direction: column;

          p {
            -webkit-margin-after: 1.25em;
            margin-block-end: 1.25em;
            -webkit-margin-before: 1.25em;
            margin-block-start: 1.25em;
            text-align: center;
            font-weight: var(--font-weight-semi-bold);
          }

          .button {
            max-width: 300px;
            border-radius: var(--default-radius);

            svg {
              max-height: 17px;
              width: auto;

              path,
              line,
              circle {
                stroke: white;
              }

              rect {
                fill: var(--black);
              }
            }

            &.--black {
              background-color: var(--black);
            }
          }
        }
      }

      .result__main {
        padding: 0 0.5em 2em;

        .result__group {
          padding: 0 0.5em;
        }

        .result__title {
          margin: 0 0.5em;
          font-weight: var(--font-weight-semi-bold);
        }

        .result__sort {
          padding: 1em 0.5em;
        }

        .status {
          display: flex;
          align-items: center;
          font-size: 0.75em;
          color: var(--green);

          &.--offline {
            color: var(--red);

            &::before {
              background-color: var(--red);
            }
          }

          &::before {
            content: "";
            margin: 0 0.5em;
            width: 0.75em;
            height: 0.75em;
            background-color: var(--green);
            border-radius: 50%;
          }
        }

        .list__header {
          margin: 1em;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          span {
            color: var(--theme-sec-text);
          }

          svg {
            margin-top: 0.5em;
          }
        }
      }

      .list {
        margin: 0;
        padding: 0 1em;

        &.--simple {
          padding: 0;
          height: inherit;
          flex-direction: column;
          justify-content: center;
          overflow: hidden;

          .mag__element {
            background: none;
            margin-top: 1em;
          }
        }

        .mag__element {
          margin-bottom: 1em;
          width: 100%;
          height: auto;
          min-height: 210px;
          max-height: none;
        }
      }
    }
  }
}

.popup--newPlace {
  width: 100%;
  max-width: 600px;
  text-align: center;

  p {
    & > span:last-child {
      font-size: 0.75em;
    }

    & > span:first-child {
      font-size: 1.0625em;
      font-weight: var(--font-weight-semi-bold);
      display: block;
    }
  }

  .popup__input {
    position: relative;

    .input__label {
      padding: 0 0.5em;
      position: absolute;
      top: -0.1em;
      left: 50%;
      font-size: 0.6875em;
      background-color: var(--theme-sec-bg);
      white-space: nowrap;
      text-overflow: ellipsis;
      transform: translateX(-50%);
    }

    input {
      width: 100%;
      margin: 0.4em 0;
      padding: 0.75em;
      color: var(--theme-text);
      font-family: inherit;
      background: none;
      border-radius: var(--button-radius);
      box-sizing: border-box;
      border: 1px solid var(--additional-color);
    }
  }

  .popup__buttons {
    margin-top: 1em;

    .button__cancel {
      margin-right: 0.5em;
      background-color: var(--additional-color);
    }

    .button__submit {
      margin-left: 0.5em;
      color: white;
      background-color: var(--red);
    }

    .button__cancel,
    .button__submit {
      padding: 0.6rem;
      width: calc(50% - 0.5em);
      font-size: 0.75em;
      border-radius: var(--button-radius);
    }
  }
}

.popup--findPlace {
  width: 100%;
  text-align: center;

  .popup__header {
    font-size: 1.125em;
    font-weight: var(--font-weight-semi-bold);
  }

  p {
    font-size: 0.875em;
    font-weight: var(--font-weight-semi-bold);
  }

  .popup__footer,
  .popup__places {
    flex-direction: column;
  }

  .popup__footer {
    margin-top: 0.5em;
  }

  .places__item {
    margin: 0.5em 0;
    padding: 0.5em 0;
    width: calc(100% - 1em);
    max-width: 400px;
    position: relative;
    border: 1px solid var(--dots-color);
    border-radius: var(--button-radius);

    .item__label {
      position: absolute;
      top: -0.5em;
      left: 1em;
      padding: 0 0.5em;
      font-size: 0.75em;
      color: var(--red);
      background-color: var(--theme-sec-bg);
    }

    .item__address {
      padding: 0 0.75em;

      span {
        font-size: 0.875em;
      }
    }

    .item__icon {
      padding: 0 0.75em;
      border-left: 1px solid var(--dots-color);
    }
  }

  .button.--arrow {
    max-width: 300px;
    background-color: var(--black);
    border-radius: var(--default-radius);

    svg {
      max-height: 17px;
      width: auto;

      path,
      line,
      circle {
        stroke: white;
      }

      rect {
        fill: var(--black);
      }
    }
  }

  .button.--back {
    margin-bottom: 0;
    padding: 0.5em 0;
  }
}

.popup__legend .legend__list {
  margin: 1em;

  .list__item {
    padding: 0.75em 0.5em;
    font-size: 0.875em;
    color: var(--theme-sec-text);

    svg {
      margin-right: 1.5em;
      flex-shrink: 0;
    }

    div {
      flex: 1;
    }
  }
}

.map__result .list {
  padding: 0;
  margin: 0;

  .mag__element {
    width: 100%;
    height: auto;
    min-height: 210px;
    max-height: none;

    padding: 1em;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    border-radius: var(--default-radius);
    background-color: var(--theme-sec-bg);
    box-sizing: border-box;

    position: relative;

    .element__main {
      display: grid;

      & > * {
        margin: 0.5em 0;

        &.element__group {
          margin-top: 0;
        }
      }
    }

    &.--planned {
      margin-top: 1em;
      padding-top: 1.5em;

      .element__icon svg {
        rect,
        line,
        path:not(.notStroke) {
          stroke: var(--orange);
        }

        .notStroke {
          fill: var(--orange);
        }
      }

      .element__magAttr .attr__title.--right {
        color: var(--orange);
      }

      button.button {
        background-color: var(--orange);
      }
    }

    .element__header {
      position: absolute;
      top: -1em;
      left: 50%;
      padding: 0.5em;
      color: white;
      font-size: 0.75em;
      transform: translateX(-50%);
      background-color: var(--orange);
      font-weight: var(--font-weight-semi-bold);
      border-radius: var(--button-radius);
    }

    .element__title {
      .--secondary {
        margin: 0 0.5em;
        font-size: 0.625em;
      }

      div:last-child {
        margin-top: 0.5em;
        font-size: 0.75em;
      }
    }

    .element__title--secondary {
      margin-top: 1em;
      color: var(--theme-text);
      font-weight: bold;
      font-size: 0.75em;
    }

    .skeleton__title {
      min-width: 60%;
    }

    .element__icons {
      min-height: 3.4645em;
      border: 1px solid var(--dots-color);
      border-radius: var(--button-radius);

      .element__icon {
        width: 50%;
        padding: 0 0.5em;
        margin: 0.5em 0;
        display: flex;
        align-items: center;
        justify-content: center;

        &.--first {
          border-right: 1px solid var(--dots-color);
        }

        .icon__desc {
          margin-left: 0.5em;
          font-size: 0.75em;
        }
      }
    }

    .place__slideShow {
      margin: 0;
      padding: 1em 0;
      --swiper-pagination-color: var(--theme-text);

      .place__slide {
        &.center {
          flex-direction: column;
        }

        img {
          width: inherit;
          height: auto;
          max-height: 220px;
          border-radius: var(--button-radius);
        }
      }

      .swiper-pagination-bullets.swiper-pagination-horizontal {
        bottom: 0;
      }
    }

    .skeleton__icons {
      margin: 0.25em 0;
      height: 2.5em;
    }

    .skeleton__detail {
      width: 75px;
      height: 1.8em;
    }

    .element__detail {
      padding: 0.3em 0.7em;
      min-width: 4.64em;
      flex-shrink: 0;
      flex-direction: column;
      background-color: var(--additional-color);
      border-radius: var(--button-radius);
      box-sizing: border-box;

      div {
        font-size: 0.625em;
        color: var(--theme-sec-text);
      }

      .--big {
        font-size: 1em;
        color: var(--theme-text);
        font-weight: var(--font-weight-semi-bold);
      }
    }

    .element__placeAttr {
      margin: 0.75em 0;

      .placeAttr__list {
        margin: 0 1em;
      }

      .item__desc {
        color: var(--theme-text);
        font-size: 0.75em;
      }
    }

    .element__magAttr {
      padding: 1em;
      position: relative;
      border: 1px solid var(--dots-color);
      border-radius: var(--button-radius);

      .attr__title {
        position: absolute;
        top: -0.75em;
        padding: 0 0.25rem;
        font-size: 0.75em;
        font-weight: var(--font-weight-medium);
        background-color: var(--theme-sec-bg);

        &.--left {
          left: 0.75rem;
        }

        &.--right {
          right: 0.75rem;
          color: var(--red);
          font-weight: var(--font-weight-semi-bold);
        }
      }

      .attr__list {
        flex-wrap: wrap;

        &.--wilde > div {
          width: 33%;
        }

        svg {
          width: 1.5em;
        }

        .attr__item {
          padding: 0.25em 0;
          display: flex;
          align-items: center;

          span {
            margin: 0.5em;
            font-size: 0.75em;
            word-break: break-all;
          }
        }
      }
    }

    .element__size {
      padding: 0.75em;
      position: relative;
      border: 1px solid var(--dots-color);
      border-radius: var(--button-radius);

      .size__title {
        position: absolute;
        left: 1em;
        top: -0.75em;
        padding: 0 0.5em;
        font-size: 0.75em;
        font-weight: var(--font-weight-medium);
        background-color: var(--theme-sec-bg);
      }

      .size__list {
        display: flex;

        .size__item {
          margin: 0 0.5em;
          padding: 0.5em;
          flex-basis: 100%;
          font-size: 0.75em;
          text-align: center;
          color: var(--theme-text);
          border: 1px solid var(--dots-color);
          border-radius: var(--button-radius);

          &.--red {
            color: var(--red);
            border-color: var(--red);
          }
        }
      }
    }

    .element__buttons {
      width: 100%;
      margin-top: 0.5em;
      align-items: stretch;

      .skeleton__detail,
      .element__detail {
        margin-right: 0.5em;
      }

      .skeleton__button {
        width: 80%;
      }

      button.button {
        flex-basis: 100%;
        margin: 0;
      }
    }
  }
}

.placeElement {
  width: 100%;
  padding-top: 2em;
  display: flex;
  flex-direction: column;
  overflow: auto;
  flex: 1;
  gap: 1em;
  &__name {
    padding: 0 1em;
    padding-bottom: 1em;
    position: relative;
    border-bottom: 1px solid var(--theme-bg);
    h2 {
      font-family: "termina";
      font-size: 0.875rem;
      margin: 0;
      margin-bottom: 4px;
      max-width: calc(100% - 4em);
    }
    .mmrating__title {
      font-size: 0.65rem;
      color: var(--theme-sec-text);
    }
    .buttonGo {
      position: absolute;
      top: 50%;
      right: 1em;
      transform: translateY(calc(-50% - 7px));
      width: 36px;
      height: 36px;
      background-color: var(--black);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      .icon {
        background-color: white;
      }
    }
  }
  &__attr {
    min-height: 18px;
    padding: 1em;
    padding-top: 0;
    width: calc(100% - 2em);
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    overflow-x: scroll;
    border-bottom: 1px solid var(--theme-bg);
    gap: 1em;
    &__item {
      display: flex;
      align-items: center;
      gap: 4px;
      white-space: nowrap;
      span {
        font-size: 0.75rem;
        font-weight: 500;
      }
      .icon {
        background-color: var(--green);
        width: 18px;
        height: 18px;
        mask-size: 18px;
        -webkit-mask-size: 18px;
      }
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  &__automation {
    padding: 0 1em 1em 1em;
    border-bottom: 1px solid var(--theme-bg);
    div:first-child {
      display: flex;
      gap: 4px;
      align-items: center;
      font-weight: 500;
      font-size: 0.65rem;
      color: var(--theme-sec-text);
      .icon {
        background-color: var(--green);
        width: 14px;
        height: 14px;
        mask-size: 14px;
        -webkit-mask-size: 14px;
      }

      span {
        &.green {
          color: var(--green);
        }
      }
    }
    div:last-child {
      font-weight: 500;
      font-size: 0.785rem;
      padding-top: 8px;
    }
  }
  &__sizes {
    padding: 0 1em 1em 1em;
    border-bottom: 1px solid var(--theme-bg);
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    .sizeItem {
      display: flex;
      padding: 8px;
      font-size: 0.65rem;
      font-weight: 600;
      color: var(--theme-sec-text);
      border: 1px solid var(--border-grey);
      border-radius: 5px;
      &.active {
        background-color: var(--black);
        color: white;
        border-color: var(--black);
      }
    }
  }
  &__price {
    padding: 0 1em 1em 1em;
    border-bottom: 1px solid var(--theme-bg);
    display: flex;
    position: relative;
    align-items: flex-end;
    flex-wrap: wrap;
    gap: 4px;
    span:first-child {
      padding-bottom: 4px;
    }
    .small {
      font-size: 0.65rem;
      color: var(--theme-sec-text);
      transform: translateY(-1.5px);
    }
  }
  &__photos {
    padding: 0 1em 0em 1em;
    width: calc(100% - 2em);
    min-height: 195px;
    max-height: 195px;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    overflow-x: scroll;
    gap: 0.5em;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
    img {
      border-radius: 10px;
      height: 195px;
      max-width: 152px;
      object-fit: cover;
    }
  }
  &__important {
    padding: 0 1em 1em 1em;
    display: flex;
    flex-direction: column;
    gap: 10px;
    &__item {
      border: 1px solid var(--border-grey);
      padding: 12px;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
    &__item__main {
      display: flex;
      align-items: center;
      gap: 10px;
      span {
        flex-grow: 1;
        font-weight: 500;
        font-size: 0.785rem;
      }
      .icon:first-child {
        background-color: var(--red);
      }
    }
    &__item__addons {
      font-weight: 500;
      font-size: 0.785rem;
      display: none;
      overflow: hidden;
      transition: max-height 0.3s ease-out;
      &.active{
        display: block;
        max-height: 500px;
      }
    }
  }
  &__tabs {
    min-height: 40vh;
    &__head {
      padding: 0 1em 0 1em;
      width: calc(100% - 2em);
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      overflow-x: scroll;
      gap: 1.5em;
      border-bottom: 1px solid var(--theme-bg);
      -ms-overflow-style: none;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
      span {
        font-weight: 600;
        font-size: 0.785rem;
        color: var(--theme-sec-text);
        white-space: nowrap;
        padding-bottom: 1em;
        &.active {
          color: var(--theme-text);
          border-bottom: 1px solid var(--theme-text);
        }
      }
    }
    &__body {
      .list {
        display: flex;
        flex-direction: column;
        padding: 0 !important;
        gap: 8px;
        &__item {
          width: calc(100% - 2em);
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          border-bottom: 1px solid var(--theme-bg);
          padding: 0 1em;
          span {
            flex-grow: 1;
            padding: 1em;
          }
          span:last-child{
            padding: 0;
            padding-bottom: 1em;
            color: var(--theme-sec-text);
            font-size: 0.785rem;
          }
        }
      }
      .htmlFromApi {
        padding: 0 1em;
      }
    }
  }

  .subtitle {
    flex-basis: 100%;
    font-weight: 500;
    font-size: 0.65rem;
    color: var(--theme-sec-text);
  }

  &__bottomBar {
    width: calc(100% - 2em);
    padding: 12px 1em 12px 1em;
    display: none;
    gap: 18px;
    background-color: var(--theme-sec-bg);
    border-top: 1px solid var(--theme-bg);
    box-shadow: 0px 1px 7.9px 0px #0000000f;
    .button.--back {
      width: 60%;
      color: var(--theme-sec-text);
    }
    .btn-empty {
      background-color: unset;
      border: 0;
      text-decoration: none;
      font-family: "termina";
      white-space: nowrap;
      color: var(--theme-sec-text);
      font-size: 0.875em;
    }
    &.ios {
      padding-bottom: 28px;
    }
    &.active {
      display: flex;
    }
  }
  .icon--info {
    background-color: var(--theme-sec-text);
  }
}

.resultList{
  display: flex;
  flex-direction: column;
  gap: 1em;
  padding: 0 .5em;
  .placeElement{
    padding-top: 1em;
    border-radius: 10px;
    background-color: var(--theme-sec-bg);
    .buttonGo {
      position: absolute;
      top: 50%;
      right: 1em;
      transform: translateY(calc(-50% - 7px));
      width: 36px;
      height: 36px;
      background-color: var(--black);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      .icon {
        background-color: white;
      }
    }
  }
}