.icon {
  width: 20px;
  height: 20px;

  -webkit-mask-size: 20px;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position: center center;

  mask-size: 20px;
  mask-repeat: no-repeat;
  mask-position: center center;

  display: block;
  position: relative;
  background-color: var(--theme-text);

  &.s24 {
    width: 24px;
    height: 24px;
    -webkit-mask-size: 24px;
    mask-size: 24px;
  }

  &.s32 {
    width: 32px;
    height: 32px;
    -webkit-mask-size: 32px;
    mask-size: 32px;
  }

  &.s40 {
    width: 40px;
    height: 40px;
    -webkit-mask-size: 40px;
    mask-size: 40px;
  }
}

.icon--mag-number {
  -webkit-mask-image: url("../icons/mag-number.svg");
  mask-image: url("../icons/mag-number.svg");
}

.icon--star {
  -webkit-mask-image: url("../icons/star.svg");
  mask-image: url("../icons/star.svg");
  background-color: var(--gray-50);
  width: 16px;
  height: 16px;
  -webkit-mask-size: 16px;
  mask-size: 16px;
  &.--red {
    background-color: var(--red);
  }
}

.icon--back {
  -webkit-mask-image: url("../icons/back.svg");
  mask-image: url("../icons/back.svg");
}

.icon--basket {
  -webkit-mask-image: url("../icons/basket.svg");
  mask-image: url("../icons/basket.svg");
}

.icon--rename {
  -webkit-mask-image: url("../icons/rename.svg");
  mask-image: url("../icons/rename.svg");
}

.icon--eye {
  -webkit-mask-image: url("../icons/eye.svg");
  mask-image: url("../icons/eye.svg");
}

.icon--eye-close {
  -webkit-mask-image: url("../icons/eye-close.svg");
  mask-image: url("../icons/eye-close.svg");
}

.icon--close {
  -webkit-mask-image: url("../icons/close.svg");
  mask-image: url("../icons/close.svg");
  background-color: white;
}

.icon--alarm {
  -webkit-mask-image: url("../icons/alarm.svg");
  mask-image: url("../icons/alarm.svg");
}

.icon--cctv {
  -webkit-mask-image: url("../icons/cctv.svg");
  mask-image: url("../icons/cctv.svg");
}

.icon--byapp {
  -webkit-mask-image: url("../icons/byapp.svg");
  mask-image: url("../icons/byapp.svg");
}

.icon--quest {
  -webkit-mask-image: url("../icons/quest.svg");
  mask-image: url("../icons/quest.svg");
}

.icon--check {
  -webkit-mask-image: url("../icons/check.svg");
  mask-image: url("../icons/check.svg");
}

.icon--dashboard {
  -webkit-mask-image: url("../icons/dashboard.svg");
  mask-image: url("../icons/dashboard.svg");
}

.icon--location {
  -webkit-mask-image: url("../icons/location.svg");
  mask-image: url("../icons/location.svg");
}

.icon--location-500 {
  -webkit-mask-image: url("../icons/location-500.svg");
  mask-image: url("../icons/location-500.svg");
}

.icon--message {
  -webkit-mask-image: url("../icons/message.svg");
  mask-image: url("../icons/message.svg");
}

.icon--heart {
  -webkit-mask-image: url("../icons/heart.svg");
  mask-image: url("../icons/heart.svg");
}

.icon--hasOnlineAccess {
  -webkit-mask-image: url("../icons/hasOnlineAccess.svg");
  mask-image: url("../icons/hasOnlineAccess.svg");
}

.icon--terminationTerms {
  -webkit-mask-image: url("../icons/terminationTerms.svg");
  mask-image: url("../icons/terminationTerms.svg");
}

.icon--rentalMinDays {
  -webkit-mask-image: url("../icons/rentalMinDays.svg");
  mask-image: url("../icons/rentalMinDays.svg");
}

.icon--shield {
  -webkit-mask-image: url("../icons/shield.svg");
  mask-image: url("../icons/shield.svg");
}

.icon--plus {
  -webkit-mask-image: url("../icons/plus2.svg");
  mask-image: url("../icons/plus2.svg");
}

.icon--info {
  -webkit-mask-image: url("../icons/info.svg");
  mask-image: url("../icons/info.svg");
}

.icon--plus2 {
  -webkit-mask-image: url("../icons/plus.svg");
  mask-image: url("../icons/plus.svg");
}

.icon--minus {
  -webkit-mask-image: url("../icons/minus.svg");
  mask-image: url("../icons/minus.svg");
}

.icon--user {
  -webkit-mask-image: url("../icons/user.svg");
  mask-image: url("../icons/user.svg");
}

.icon--help {
  -webkit-mask-image: url("../icons/help.svg");
  mask-image: url("../icons/help.svg");
}

.icon--chevron-right {
  -webkit-mask-image: url("../icons/chevron_right.svg");
  mask-image: url("../icons/chevron_right.svg");
}

.icon--chevron-up {
  -webkit-mask-image: url("../icons/chevron_right.svg");
  mask-image: url("../icons/chevron_right.svg");
  rotate: -90deg;
}

.icon--chevron-down {
  -webkit-mask-image: url("../icons/chevron_right.svg");
  mask-image: url("../icons/chevron_right.svg");
  rotate: 90deg;
}

.icon--chevron-left {
  -webkit-mask-image: url("../icons/chevron_left.svg");
  mask-image: url("../icons/chevron_left.svg");
}

.icon--arrow-right {
  -webkit-mask-image: url("../icons/arrow-right.svg");
  mask-image: url("../icons/arrow-right.svg");
}

.icon--share {
  -webkit-mask-image: url("../icons/share.svg");
  mask-image: url("../icons/share.svg");
}

.icon--terminate {
  -webkit-mask-image: url("../icons/terminate.svg");
  mask-image: url("../icons/terminate.svg");
}

.icon--payments {
  -webkit-mask-image: url("../icons/payments.svg");
  mask-image: url("../icons/payments.svg");
}

.icon--mag-details {
  -webkit-mask-image: url("../icons/mag-details.svg");
  mask-image: url("../icons/mag-details.svg");
}

.icon--insurance {
  -webkit-mask-image: url("../icons/insurance.svg");
  mask-image: url("../icons/insurance.svg");
}

.icon--dots {
  -webkit-mask-image: url("../icons/dots.svg");
  mask-image: url("../icons/dots.svg");
}

.icon--calendar {
  -webkit-mask-image: url("../icons/calendar.svg");
  mask-image: url("../icons/calendar.svg");
}

.icon--price {
  -webkit-mask-image: url("../icons/price.svg");
  mask-image: url("../icons/price.svg");
}

.icon--lock {
  -webkit-mask-image: url("../icons/lock.svg");
  mask-image: url("../icons/lock.svg");
}

.icon--paid {
  -webkit-mask-image: url("../icons/paid.svg");
  mask-image: url("../icons/paid.svg");
}

.icon--trash {
  -webkit-mask-image: url("../icons/trash.svg");
  mask-image: url("../icons/trash.svg");
  -webkit-mask-size: 16px;
  mask-size: 16px;
}
