.basket {
  display: flex;
  flex-direction: column;

  section {
    flex: 1;
    overflow-y: auto;
  }

  .basket__bg {
    position: fixed;
    top: 0;
    left: 0;
    margin: 0;
    width: 100vw;
    height: calc(var(--vh, 1vh) * 100);
    background-color: var(--toast-bg);
    -webkit-animation: darken 200ms ease-in;
            animation: darken 200ms ease-in;
    z-index: 10;

    &.--close {
      -webkit-animation-name: ligthen;
              animation-name: ligthen;
    }
  }

  .details--basket {
    position: relative;
    width: 100%;
    background-color: var(--black);
    border-top-left-radius: var(--popup-radius);
    border-top-right-radius: var(--popup-radius);
    z-index: 11;

    .basket__main {
      background-color: var(--theme-sec-bg);
      border-top-left-radius: var(--popup-radius);
      border-top-right-radius: var(--popup-radius);
    }

    .basket__title {
      color: white;
      text-align: center;
      font-size: 0.75em;
      font-weight: var(--font-weight-semi-bold);

      .arrow {
        margin-right: 0.5em;

        &.--down {
          transform: rotate(180deg);
          transform-origin: 50% 50%;
        }
      }
    }

    .basket__details,
    .basket__summary {
      padding: 1.5em 1.5em 1em 1.5em;
    }

    .basket__summary--short {
      padding: 0 1.5em;
      transition: height 200ms, opacity 400ms;
    }

    .basket__header {
      margin: 1em 0;

      .header__desc,
      .header__amount {
        & > div:first-child {
          color: white;
          font-size: 0.875em;
          font-weight: var(--font-weight-semi-bold);
        }

        & > div:nth-child(2) {
          margin-top: 0.35em;
          font-size: 0.75em;
          color: var(--theme-sec-text);
        }
      }

      .header__amount {
        & > div:last-child {
          text-align: center;
        }

        div div {
          margin: 0 0.25em;
          padding: 0 0.5em;
        }
      }
    }

    .summary__container {
      overflow-y: auto;

      .basket__summary {
        -webkit-animation: moveIn 300ms ease-out;
                animation: moveIn 300ms ease-out;
      }

      &.--close .basket__summary {
        -webkit-animation-name: moveOut;
                animation-name: moveOut;
      }
    }

    .basket__summary {
      display: flex;
      flex-direction: column;
      padding-top: 0;
      color: white;

      .basket__decs svg {
        margin-right: 0.5em;
        max-width: 17px;
        height: auto;

        &.terminate {
          line,
          circle {
            stroke: white;
            stroke-width: 2;
          }
        }
      }

      hr {
        margin-top: 1.5em;
        border-width: 1px 0 0 0;
        border-style: dashed;
      }
    }

    .details__header {
      color: var(--theme-sec-text);
    }

    .basket__decs {
      &:not(&:last-child) {
        margin-bottom: 0.5em;
      }

      & > div:first-child {
        color: var(--theme-sec-text);
        font-size: 0.75em;
      }

      & > div:last-child {
        font-size: 0.875em;
        font-weight: var(--font-weight-semi-bold);
      }

      div.--big {
        font-size: 1.5em;
      }
    }

    .details__list .details__col {
      width: 40%;
      max-width: 400px;
    }

    button.button {
      padding: 0.9em 0.5em;

      &.--coupon {
        padding: 0.825em 0.5em;
      }
    }
  }
}
