.orderInfo__summary {
  margin: 0 1em 1em;
  background-color: var(--theme-sec-bg);
  border-radius: 10px;

  &__schedule {
    margin: 0 1rem;
    padding-bottom: 0.75rem;
    &__content {
      :first-child {
        font-size: 0.875rem;
        font-weight: 600;
      }
      :not(:first-child) {
        margin-top: 0.4em;
        font-size: 0.75rem;
        color: var(--theme-sec-text);
      }
    }
    &__price {
      font-size: 0.875rem;
      font-weight: 600;
    }
  }

  &__header {
    padding: 1rem;
    font-size: 0.875rem;
    font-weight: 600;
  }

  &__content {
    margin: 0 1rem;
    &__item {
      margin-bottom: 8px;
      display: flex;
      justify-content: space-between;
      gap: 1em;
      font-size: 0.75rem;
    }
  }

  &__sum {
    padding: 0.75rem 1rem;
    border-top: 2px solid var(--theme-bg);
    display: flex;
    font-size: 0.875rem;
    font-weight: 600;
    gap: 1em;
    justify-content: space-between;
  }

  &__button {
    padding: 0.5rem 1rem;
    font-size: 0.675rem;
    text-align: center;
    cursor: pointer;
  }
}

.orderInfo__periodsToPay {
  padding: 0 1em;
}
