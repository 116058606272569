.mag__number {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  .mag__icon {
    display: flex;

    .icon{
      width: 35px;
      height: 35px;
      mask-size: 35px;
      -webkit-mask-size: 35px;
    }
  }

  .mag__desc {
    border: 2px solid var(--black);
    border-radius: 7px;
    margin: 0 0.25em;
    min-width: 32px;
    height: 32px;
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: center;

    span{
      font-size: 8px;
      font-weight: 600;
      padding-left: 6px;
    }

    div{
      text-align: center;
      padding: 0 6px;
    }
  }

  &.light{
    .icon{
      background-color: white;
    }
    .mag__desc{
      border-color: white;
    }
  }
}
