$green: var(--green, green);
$red: var(--red, red);

.modal__deliveryPoints {
  width: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1rem;

  .input__default {
    margin: 0;
  }

  .map__leaflet {
    width: 100%;
    flex: 1;

    & > div {
      height: 100%;
      height: calc(55 * var(--vh, 1vh));
      border-radius: var(--default-radius);
    }
    .leaflet-top,
    .leaflet-bottom {
      z-index: 60;
    }

    .leaflet-pane {
      z-index: 40;
    }

    .marker-cluster-small {
      background-color: rgba($color: $green, $alpha: 0.4);
      div {
        background-color: rgba($color: $green, $alpha: 0.65);
        color: white;
      }
    }

    .leaflet-tooltip {
      padding: 0;
      color: var(--black, black);
      font-family: montserrat, sans-serif;
      text-align: center;
      opacity: 1 !important;
      background: none;
      box-shadow: none;
      border: none;

      &::before {
        display: none;
      }
    }

    .marker__popup {
      min-width: 240px;
      display: flex;
      flex-direction: column;
      gap: 1rem;

      &__header {
        display: flex;
        gap: 0.5rem;
        align-items: center;
        &__dot {
          flex-shrink: 0;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background-color: $red;
          &.--active {
            background-color: $green;
          }
        }
      }

      &__info {
        display: flex;
        column-gap: 0.25rem;
        flex-wrap: wrap;
      }

      &__label {
        font-weight: 600;
        margin-bottom: 0.25rem;
      }

      &__availability {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 1rem;

        div {
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }
}
