.map__details {
  .details__mag {
    .mag__text,
    .mag__title,
    .mag__title--secondary {
      margin: 0.5rem;
    }

    .mag__text {
      font-size: 0.675em;
    }

    .mag__title {
      div:first-child {
        font-size: 0.75em;
      }

      div:last-child {
        font-size: 1.3125em;
        font-weight: var(--font-weight-semi-bold);
      }
    }

    .skeleton__title {
      height: 1.125em;
    }

    .mag__title--secondary {
      font-size: 0.75em;
      font-weight: var(--font-weight-medium);
    }

    .skeleton__title--secondary {
      height: 0.75em;
    }

    .skeleton__title,
    .skeleton__title--secondary {
      margin: 0.25rem;
      width: 80%;
      max-width: 400px;
    }

    .mag__section {
      text-align: center;
    }

    .mag__size {
      margin: 0.5rem;
      font-size: 0.6875em;

      .size__title {
        margin: 0.5em 0;
      }

      .size__list {
        display: flex;

        .size__item {
          margin-right: 1em;
          text-align: center;

          div:first-child {
            font-size: 120%;
            font-weight: var(--font-weight-semi-bold);
          }

          div:last-child {
            color: var(--theme-sec-text);
          }
        }
      }
    }

    .mag__graphics {
      padding: 1em 0;

      .mag__marker {
        margin: 1em;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        .marker {
          margin: 0.5em;
          width: 34px;
          height: 34px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: var(--theme-text);
          border-radius: 50%;

          .arrow {
            border: solid var(--theme-bg);
            border-width: 0 2px 2px 0;
            display: inline-block;
            padding: 4px;

            &.--right {
              margin-right: 4px;
              transform: rotate(-45deg);
            }

            &.--left {
              margin-left: 4px;
              transform: rotate(135deg);
            }
          }
        }

        span {
          font-size: 0.75em;
        }
      }

      .mag__svg {
        position: relative;

        img {
          width: 30vmin;
          height: 30vmin;
          max-width: 120px;
          max-height: 120px;
        }

        .svg__title {
          position: absolute;
          top: 50%;
          left: 50%;
          color: white;
          font-size: 0.875em;
          font-weight: var(--font-weight-semi-bold);
          transform: translate(-50%, -50%);
        }
      }
    }
  }

  .map__result .list {
    .list__element.--big {
      margin: 0.5em 1em;
      width: 100%;
      height: auto;
      max-height: none;
    }
  }

  .popup__size {
    .scene {
      margin: 6em 0;
      transform-style: preserve-3d;
      transform: rotateX(-24deg) rotateY(-32deg);

      .cuboid {
        transform-style: preserve-3d;
      }

      .cuboid__side {
        position: absolute;
        top: 50%;
        left: 50%;
        height: 100%;
        width: 100%;
        border: 2px solid var(--theme-text);
        box-sizing: border-box;

        svg {
          display: none;
          width: 100%;
          height: auto;
        }

        .slide__label {
          position: absolute;

          &.--left {
            left: -100%;
            top: 50%;
            transform: translateY(-50%);
          }

          &.--right {
            left: -100%;
            top: 50%;
            transform: translateY(-50%) rotateY(-180deg);
          }

          &.--bottom {
            left: 50%;
            bottom: -50%;
            transform: translateX(-50%);
          }
        }
      }
    }
  }
}

.placeResult {
  &__location {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: var(--black);
    border-bottom-left-radius: var(--popup-radius);
    border-bottom-right-radius: var(--popup-radius);
    z-index: 1;
  }
  &__mag {
    margin-top: -40px;
    padding: 50px 1em 2em;
    background-color: var(--theme-sec-bg);
    border-bottom-left-radius: 40px;
    border-bottom-right-radius: 40px;

    &__title {
      display: flex;
      gap: 6px;
      align-items: center;
      justify-content: center;
      font-family: "termina";
      h2 {
        font-size: 1rem;
      }
      span {
        display: block;
        width: 4px;
        height: 4px;
        border-radius: 100%;
        background-color: var(--theme-text);
      }
    }
    &__graphics {
      width: 100%;
      padding-top: 0.5em;
      display: flex;
      gap: 1em;
      align-items: center;
      .mag__img {
        flex-grow: 1;
        text-align: center;
        height: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          max-height: 200px;
          object-fit: cover;
          max-width: 90%;
        }
      }
      .mag__marker {
        display: flex;
        flex-direction: column;
        gap: 4px;
        align-items: center;
        justify-content: center;
        .marker {
          border-radius: 100%;
          background-color: var(--black);
          width: 36px;
          height: 36px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        span {
          font-size: 10px;
          font-weight: 500;
        }
        .icon {
          background-color: white;
        }
      }
    }
    &__buttons {
      padding-top: 1em;
      display: flex;
      align-items: center;
      justify-content: space-around;
      span {
        position: relative;
        font-size: 0.785rem;
        font-weight: 500;
        color: var(--theme-sec-text);
        &.active {
          font-weight: 600;
          color: var(--theme-text);
          &::after {
            content: "";
            position: absolute;
            top: 22px;
            left: 50%;
            transform: translateX(-50%);
            display: block;
            width: 5px;
            height: 5px;
            border-radius: 100%;
            background-color: var(--theme-text);
          }
        }
      }
    }
  }

  &__magList {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 1em;
  }

  &__sizes {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 1em;
    &__item {
      min-height: 44px;
      display: flex;
      align-items: center;
      gap: 4px;
      background-color: var(--theme-sec-bg);
      border-radius: 10px;
      padding: 0 1em;
      h3 {
        margin: 0;
        font-size: 0.875rem;
        font-weight: 600;
      }
      span {
        font-size: 0.875rem;
        font-weight: 500;
      }
    }
    h4 {
      margin: 0;
      font-size: 0.875rem;
      font-weight: 600;
    }
    &__list {
      display: flex;
      flex-direction: column;
      background-color: var(--theme-sec-bg);
      border-radius: 10px;
      &__item {
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 0 1em;
        border-bottom: 1px solid var(--theme-bg);
        height: 44px;
        font-weight: 500;
        &::last-child {
          border: 0;
        }
      }
      .iconWrap {
        width: 18px;
        height: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--red);
        border-radius: 100%;
        .icon {
          background-color: white;
        }
      }
    }
  }
}

.details__location {
  .location__main {
    padding: 0 1em 1em;
    text-align: center;

    .location__title {
      color: var(--white);
      margin-bottom: 0.4rem;
      font-size: 0.8125em;
    }

    .location__construction__top {
      color: var(--orange);
      font-size: 0.75rem;
      font-weight: 600;
    }

    .location__address {
      color: var(--theme-sec-text);
      font-size: 0.75em;
      font-weight: var(--font-weight-medium);
    }

    .location__distance,
    .location__construction__bottom {
      color: #cbcbcb;
      margin-top: 0.2rem;
      font-size: 0.625rem;
      font-weight: 600;
    }

    svg {
      margin-right: 0.75em;
      width: auto;
      height: 13px;
    }

    .skeleton__desc {
      width: 60%;
    }
  }
}

.magElement {
  display: flex;
  flex-direction: column;
  background-color: var(--theme-sec-bg);
  border-radius: 10px;
  &__head {
    position: relative;
    padding: 1em;
    display: flex;
    align-items: center;
    gap: 6px;
    border-bottom: 1px solid var(--theme-bg);
    .amount {
      &__desc {
        background-color: var(--theme-sec-bg);
        border: 1px solid var(--border-grey);
        border-radius: 5px;
        padding: 5px 10px;
        position: absolute;
        top: -20px;
        left: 15px;
        font-weight: 600;
        display: none;
      }
      &__bg {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        width: 6px;
        height: 16px;
        border-radius: 2px;
        background-color: var(--border-grey);
      }
      span {
        display: block;
        border-radius: 2px;
        width: 100%;
        &.empty {
          height: 40%;
          background-color: var(--red);
        }
        &.average {
          height: 65%;
          background-color: var(--orange);
        }
        &.full {
          height: 100%;
          background-color: var(--green);
        }
      }
      &:hover {
        .amount__desc {
          display: block;
        }
      }
    }
    h2 {
      margin: 0;
      flex-grow: 1;
      font-family: "termina";
      font-size: 1em;
    }
    span {
      font-weight: 600;
      color: var(--theme-sec-text);
      font-size: 0.785rem;
    }
  }
  &__important {
    display: grid;
    grid-template-columns: 1fr 10px 1fr;
    align-items: center;
    border-bottom: 1px solid var(--theme-bg);
    padding: 1em;
    .divider {
      display: block;
      height: 24px;
      width: 1px;
      background-color: var(--theme-bg);
      grid-area: 1 / 2 / 2 / 3;
    }
    &__item {
      width: 100%;
      display: flex;
      gap: 4px;
      align-items: center;
      justify-content: center;
      font-size: 0.785rem;
      font-weight: 500;
      .icon {
        background-color: var(--red);
      }
    }
  }
  &__body {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 6px;
    align-items: center;
    border-bottom: 1px solid var(--theme-bg);
    padding: 1em;
    font-size: 0.785rem;
    font-weight: 500;
    .attrItem {
      display: flex;
      align-items: center;
      gap: 4px;
      span {
        white-space: nowrap;
      }
    }
  }
  &__footer {
    display: flex;
    align-items: center;
    padding: 0.5em;
    gap: 10px;
    &__price {
      height: 45px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: var(--theme-bg);
      border-radius: 5px;
      padding: 0 1em;
      span:first-child {
        font-family: "termina";
      }
      span:last-child {
        font-size: 10px;
        font-weight: 500;
        color: var(--theme-sec-text);
      }
    }
    button {
      height: 45px;
    }
  }
  .badge {
    position: absolute;
    top: -16px;
    left: 50%;
    transform: translateX(-50%);
    background-color: var(--theme-sec-bg);
    border: 1px solid;
    border-color: var(--border-grey);
    padding: 8px;
    border-radius: 5px;
    font-size: 0.785rem;
    font-weight: 600;
  }
  &.--badge {
    position: relative;
    padding-top: 1em;
    margin-top: 1em;
  }
  &.--orderLast {
    order: 99;
  }
}
