.mmrating{
  display: flex;
  align-items: center;
  gap: 4px;
  &__title{
    font-size: 1em;
  }
  &__stars{
    display: flex;
    align-items: center;
  }
}