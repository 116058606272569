.entrance {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .entrance__main {
    position: relative;
    flex: 1;

    &.--shadow::before {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      top: 50%;
      left: 50%;
      box-shadow: 0 0 5em 5em var(--theme-text);
      transform: translate(-50%, -50%);
      border-radius: 50%;
    }
  }

  .login__main {
    flex: 1;

    & > * {
      text-align: center;
      width: 100%;
    }
  }

  .input {
    margin: 1em;
    display: flex;
    align-items: center;
    min-height: 4.1875em;
    background-color: var(--theme-sec-bg);
    border-radius: var(--default-radius);
    border: 2px solid transparent;
    box-sizing: border-box;

    &.--warning {
      -webkit-animation: warning 1.5s infinite ease;
              animation: warning 1.5s infinite ease;

      .input__main {
        color: var(--red) !important;
      }

      svg g {
        path,
        line {
          stroke: var(--red);
        }

        rect {
          fill: var(--red);
        }
      }
    }

    .input__main {
      flex-grow: 1;
      font-size: 1.5em;
      letter-spacing: 1px;

      span {
        position: relative;
        word-break: break-all;
        white-space: pre-wrap;

        &.--focus::after {
          content: "";
          position: absolute;
          bottom: 0.1em;
          right: -0.1em;
          width: 0.1em;
          height: 1em;
          -webkit-animation: cursor 1s infinite linear;
                  animation: cursor 1s infinite linear;
        }
      }

      @-webkit-keyframes cursor {
        0%,
        50% {
          background: none;
        }
        60%,
        100% {
          background: var(--theme-text);
        }
      }

      @keyframes cursor {
        0%,
        50% {
          background: none;
        }
        60%,
        100% {
          background: var(--theme-text);
        }
      }
    }

    .input__main.--secondary {
      color: var(--dark-grey);
    }

    & > svg {
      width: 20px;
      padding: 0 1em;
    }

    @-webkit-keyframes warning {
      50% {
        box-shadow: 0 0 0.5em var(--red);
      }
    }

    @keyframes warning {
      50% {
        box-shadow: 0 0 0.5em var(--red);
      }
    }
  }

  .entrance__menu {
    position: static;
    height: auto;
  }

  #gate,
  #gateway {
    position: relative;
  }

  #gate {
    #przesuwna {
      transform: translateX(0);
      transition: transform ease-out;

      &.--opened {
        transform: translateX(225px);
      }
    }

    #Ledy {
      -webkit-animation: shining ease;
              animation: shining ease;
    }

    @-webkit-keyframes shining {
      0%,
      50%,
      100% {
        opacity: 0;
      }

      25%,
      75% {
        opacity: 1;
      }
    }

    @keyframes shining {
      0%,
      50%,
      100% {
        opacity: 0;
      }

      25%,
      75% {
        opacity: 1;
      }
    }
  }

  #gateway #gateway-main #door {
    transform: perspective(2000px) translate3d(0px, 0px, 0px) rotateY(0);
    transform-origin: right;
    transition-duration: 2s;

    &.--opened {
      transform: perspective(2000px) translate3d(0px, 0px, 0px) rotateY(-68deg);
    }
  }
}
