.warehouse__animation {
  position: relative;
  margin: 2em 0;

  &.--warning::before,
  &.--shadow::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 0;
    height: 0;
  }

  &.--warning::before {
    -webkit-animation: shine 25s linear infinite;
            animation: shine 25s linear infinite;
  }

  &.--shadow::before {
    box-shadow: 0 0 4em 5em white;
  }

  &.--animate .animation__interior {
    -webkit-animation: up 3s ease;
            animation: up 3s ease;

    .animation__shadow {
      -webkit-animation: down 3s ease;
              animation: down 3s ease;
    }

    .animation__door {
      transition: transform 1.5s ease 500ms;
    }
  }

  &.--opened .animation__interior .animation__door {
    &.--left {
      transform: translateX(-34px) rotateY(-75deg);
    }

    &.--right {
      transform: translateX(34px) rotateY(75deg);
    }
  }

  .animation__interior {
    position: relative;
    width: 218px;
    height: 239px;
    background-image: url(../../assets/warehouse/interior.png);
    background-repeat: no-repeat;
    background-size: contain;
    transform: scale(0.9);
    z-index: 1;

    .animation__door {
      position: absolute;
      -o-object-fit: contain;
         object-fit: contain;
      top: 12px;
      z-index: 2;

      &.--left {
        width: 100px;
        height: 208px;
        left: 7px;
      }

      &.--right {
        width: 101px;
        height: 212px;
        left: 107px;
      }
    }

    .animation__shadow {
      position: absolute;
      bottom: -12px;
    }
  }

  .warehouse__popup {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 90%;
    height: 90%;
    padding: 1em;
    color: white;
    text-align: center;
    transform: translate(-50%, -50%);
    box-shadow: inset 0 0 5em 5em rgba(0, 0, 0, 0.5);
    box-sizing: border-box;
    z-index: 2;

    & > div {
      margin: 1.4em 0.7em;
    }

    .popup__header {
      div {
        font-size: 0.625em;
        font-weight: var(--font-weight-semi-bold);
      }

      svg g {
        path,
        line {
          stroke: var(--red);
        }
      }
    }

    .popup__title {
      div:first-child {
        font-size: 0.8125em;
        margin-bottom: 0.3em;
      }

      div {
        font-size: 0.75em;
        font-weight: var(--font-weight-medium);
      }
    }

    .popup__button {
      padding: 1em 0;
      font-size: 0.75em;
      background-color: var(--red);
      border-radius: var(--button-radius);
    }
  }

  @-webkit-keyframes shine {
    0%,
    10%,
    20%,
    30%,
    40% {
      box-shadow: 0 0 5em 5em var(--theme-bg);
    }
    5%,
    15%,
    25%,
    35%,
    45%,
    95% {
      box-shadow: 0 0 5.4em 5.4em var(--red);
    }
  }

  @keyframes shine {
    0%,
    10%,
    20%,
    30%,
    40% {
      box-shadow: 0 0 5em 5em var(--theme-bg);
    }
    5%,
    15%,
    25%,
    35%,
    45%,
    95% {
      box-shadow: 0 0 5.4em 5.4em var(--red);
    }
  }

  @-webkit-keyframes up {
    0%,
    100% {
      transform: translateY(0) scale(0.9);
    }
    30%,
    70% {
      transform: translateY(-15px) scale(0.9);
    }
  }

  @keyframes up {
    0%,
    100% {
      transform: translateY(0) scale(0.9);
    }
    30%,
    70% {
      transform: translateY(-15px) scale(0.9);
    }
  }

  @-webkit-keyframes down {
    0%,
    100% {
      bottom: -12px;
    }
    30%,
    70% {
      bottom: -27px;
    }
  }

  @keyframes down {
    0%,
    100% {
      bottom: -12px;
    }
    30%,
    70% {
      bottom: -27px;
    }
  }
}
