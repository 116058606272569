@use "sass:math";

.warehouse {
  .warehouse__main {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: calc(100 * var(--vh, 1vh) - 22.86em - 64px);

    @media screen and (max-height: 800px) {
      height: calc(100 * var(--vh, 1vh) - 20.86em - 64px);
    }

    .mag__number {
      position: fixed;
      top: 1em;
      left: 50%;
      background-color: var(--theme-bg);
      transform: translateX(-50%);
      z-index: 11;
    }

    .swiper-slide .slide {
      @media screen and (max-height: 640px) {
        transform: scale(0.8);

        &:not(.--selected) {
          transform: scale(0.6);
        }
      }

      @media screen and (max-height: 550px) {
        transform: scale(0.6);

        &:not(.--selected) {
          transform: scale(0.4);
        }
      }

      @media screen and (min-height: 800px) {
        transform: scale(1.2);

        &:not(.--selected) {
          transform: scale(1);
        }
      }
    }
  }

  .menu__main {
    position: absolute;
    top: calc(100 * var(--vh, 1vh) - 22.86em - 64px);
    left: 0;
    right: 0;
    height: 100%;
    transition: top 500ms;
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: var(--theme-sec-bg);
    border-top-left-radius: var(--menu-radius);
    border-top-right-radius: var(--menu-radius);
    z-index: 12;

    @media screen and (max-height: 800px) {
      top: calc(100 * var(--vh, 1vh) - 20.86em - 64px);
    }

    & > div {
      max-width: 900px;

      &:last-child {
        height: calc(100% - 5.5em);
      }
    }

    .menu__status {
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      width: 100%;
      border-top-left-radius: var(--menu-radius);
      border-top-right-radius: var(--menu-radius);
      z-index: 1;

      &::after {
        content: "";
        position: absolute;
        width: 80%;
        height: 0;
        left: 10%;
        background-color: var(--theme-sec-bg);
        box-shadow: 0 0 0.8em 0.8em var(--theme-sec-bg);
      }

      .status__options {
        position: -webkit-sticky;
        position: sticky;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;

        h4 {
          margin-block: 1em;
        }

        .status__option {
          margin: 0.5em;
          width: 20%;
          display: flex;
          flex-direction: column;
          align-items: center;

          .ring--cut::after {
            content: "";
            position: absolute;
            height: 22px;
            width: 1px;
            transform: translateX(-8px) translateY(-2px) rotate(45deg);
            background-color: var(--theme-text);
          }

          span {
            padding-top: 0.2em;
            font-size: 0.875em;
            font-weight: var(--font-weight-medium);
          }
        }
      }
    }

    hr {
      width: 100%;
      height: 1px;
      border-width: 0 0 1px 0;
      border-color: var(--additional-color);
    }

    .menu__section {
      padding: 1em 2em;

      p {
        font-weight: var(--font-weight-semi-bold);
      }
    }

    .menu__buttons {
      margin: 1em 0;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;

      &.--top {
        align-items: flex-start;

        & > .button__circle:not(.--big) {
          width: 24vmin;
          margin-top: 4em;
        }

        @media screen and (max-height: 800px) {
          margin: 0;
        }
      }

      .buttons__col {
        flex-direction: column;
      }

      .buttons__row {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-basis: 50%;
        flex-grow: 1;

        hr {
          margin: 0;
          width: 1px;
          height: 3em;
          border-width: 0 1px 0 0;
        }
      }

      .menu__button {
        margin: 0.5em;
        width: 33%;
        flex-direction: column;

        span {
          margin-top: 1em;
          text-align: center;
          font-size: 0.875em;
          font-weight: var(--font-weight-medium);
        }

        .icon {
          background-color: var(--red);
        }
      }
    }

    .menu__history {
      .history__date {
        margin-top: 0.5em;
        font-size: 0.875em;
      }

      .history__item {
        margin-bottom: 1em;

        .item__left {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          flex-grow: 1;
        }

        .history__symbol {
          margin: 0 1em;
          width: 2em;
          height: 2em;
          flex-grow: 0;
          border-radius: 50%;
          border: 1px solid var(--theme-sec-text);

          svg {
            width: auto;
            height: 1em;
          }
        }

        .history__desc {
          max-width: 70%;

          div:first-child {
            font-size: 0.875em;
            margin-bottom: 0.4em;
          }

          div:nth-child(2) {
            min-height: 2.4em;
            font-size: 0.75em;
          }
        }
      }
    }

    .access__item {
      .access__desc {
        max-width: 70%;

        div:first-child {
          font-size: 0.875em;
          margin-top: 0;
        }

        div {
          margin-top: 0.4em;
          font-size: 0.75em;
        }
      }

      .access__symbol {
        margin: 1em;
        width: 1.6em;
        height: 1.6em;
        border-radius: 50%;
        border: 1px solid var(--theme-sec-text);

        svg #arrow-right {
          path,
          line {
            stroke: var(--theme-text);
          }
        }

        &.--big {
          width: 2em;
          height: 2em;

          span {
            font-size: 1.5em;
          }
        }
      }
    }

    .menu__details {
      .calendar .calendar__main .calendar__inputs .calendar__input {
        .input__main,
        .input__shadow div {
          background-color: var(--theme-bg);
        }
      }

      .list {
        padding: 0;

        & > div:not(.list__element) {
          width: 100%;
          max-width: 408px;
        }

        .list__element.--big {
          max-width: 408px;
          background-color: var(--theme-bg);
          aspect-ratio: unset;

          b {
            color: var(--theme-text);
          }

          .element__invoice {
            margin: 0.5em 0;
          }
        }
      }

      .details__buttons {
        margin: 1.5em 0;

        .button__cancel {
          width: 100%;
          margin: 0.4em 0;
          padding: 0.75em 0.4em;
          font-size: 0.875em;
          color: var(--dark-grey);
          text-align: center;
          box-sizing: border-box;
        }
      }
    }

    .menu__more {
      font-size: 0.875em;
      text-align: center;
      color: var(--red);

      &.--high {
        min-height: 3.68em;
      }
    }

    .menu__promotion {
      margin: 2em 0 2.5em;
      height: 2em;
      width: 100%;
      border: 1px solid var(--red);
      border-radius: var(--button-radius);
      display: flex;
      align-items: center;
      justify-content: space-around;

      .promotion__line {
        position: relative;
        width: 100%;
        height: 100%;
        border-right: 1px solid var(--red);

        &.--selected {
          background-color: var(--red);
        }

        &:last-child {
          border-right: none;
        }

        .promotion__label {
          &.--selected span {
            font-weight: var(--font-weight-semi-bold);

            &:not(.--bottom) {
              color: white;
            }
          }

          span {
            font-size: 0.875em;
            font-weight: var(--font-weight-medium);

            &.--bottom {
              position: absolute;
              top: 3em;
              left: 50%;
              transform: translateX(-50%);
            }
          }
        }
      }
    }

    .menu__resignation {
      .title {
        text-align: center;
      }

      .resignation__main {
        padding: 2em 2em 1em;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }

      .resignation__steps > div {
        margin-bottom: 2em;
      }

      .popup--photo {
        width: 100%;
        max-width: 600px;

        & > div {
          margin: 1em 1em 2em;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      .resignation__summary .resignation__text {
        margin: 1.5em 2.5em;
        text-align: center;
        font-size: 0.875em;
      }

      .resignation__warehouses {
        position: relative;
        height: 154px;
        z-index: 1;

        &::before {
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          width: 0;
          height: 0;
          box-shadow: 0 0 5em 3em white;
          z-index: -2;
        }

        .warehouse {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%) scale(0.6);

          &.--left {
            opacity: 0.75;
            transform: translate(-20%, -50%) scale(0.5);
            z-index: -1;
          }

          &.--right {
            opacity: 0.75;
            transform: translate(-80%, -50%) scale(0.5);
            z-index: -1;
          }
        }
      }

      .resignation__calendar {
        .calendar__labels {
          margin-bottom: 1em;

          div {
            flex-basis: 40%;
            padding: 0.5em;
            text-align: center;
            border-bottom: solid var(--theme-text);

            &:first-child {
              color: var(--red);
              border-color: var(--red);
            }

            span {
              font-weight: var(--font-weight-semi-bold);
            }
          }
        }

        .calendar__info {
          margin: 1.5em 0;
          text-align: center;
          font-size: 0.875em;
        }
      }

      .resignation__list {
        .input__default .input__label {
          background-color: var(--theme-sec-bg);
        }

        .list__item {
          display: flex;
          padding: 1em 0;
          border: solid var(--additional-color);
          border-width: 1px 0 0 0;

          & > div:nth-child(2) {
            width: 100%;
          }

          &:last-child {
            border-width: 1px 0;
          }

          .item__icon,
          .item__extra {
            width: 4em;
            flex-shrink: 0;
          }

          .item__icon svg {
            #Ellipse_402,
            #Path_721,
            circle,
            rect,
            line {
              stroke-width: 1.5;
              stroke: var(--theme-text);
            }

            path:not(#Path_721) {
              fill: var(--theme-text);
            }
          }

          .item__extra {
            text-align: center;
            font-size: 0.875em;
            font-weight: var(--font-weight-semi-bold);
          }

          .item__desc {
            div:first-child {
              font-size: 0.875em;
              font-weight: var(--font-weight-medium);
            }

            div:nth-child(2) {
              margin-top: 0.5em;
              font-size: 0.75em;
              color: var(--theme-sec-text);
            }
          }
        }

        .list__label {
          padding: 0.5em 0;
          font-weight: var(--font-weight-medium);
        }

        .list__summary {
          font-size: 0.875em;
          text-align: end;

          span {
            font-weight: var(--font-weight-semi-bold);
          }
        }
      }
    }

    .menu__full {
      width: 100%;

      .menu__goodbye {
        padding: 1em;
        text-align: center;
        color: var(--theme-sec-text);
        font-weight: var(--font-weight-semi-bold);
      }

      .menu__section {
        padding: 1em 0;
        height: calc(100% - 2em);
        display: flex;
        flex-direction: column;

        .element__subTitle--arrow {
          display: flex;
          align-items: center;
          color: var(--theme-sec-text);
          font-weight: var(--font-weight-medium);
          font-size: 0.875em;
          .icon {
            background-color: var(--theme-sec-text);
          }
        }

        & > *:not(.resignation__main):not(.payment) {
          padding: 0 2em;
        }

        .menu__filter svg {
          margin-right: 0.5em;
        }

        & > div:last-child {
          flex: 1;
          overflow-y: auto;
        }
      }
    }

    $arrow_size: 20px;

    .menu__arrows {
      margin: 1em auto;
      position: relative;
      width: $arrow_size;
      height: $arrow_size;
      transform: rotate(45deg);
      border: solid var(--border-grey);
      border-width: 0 3px 3px 0;

      $halfArrow: math.div($arrow_size, 2);
      $quarterArrow: math.div($arrow_size, 4);

      &::before {
        content: "";
        position: absolute;
        left: 50%;
        width: $halfArrow;
        height: $halfArrow;
        top: 50%;
        margin: (-$quarterArrow) 0 0 (-$quarterArrow);
        border: solid var(--border-grey);
        border-width: 0 2px 2px 0;
        -webkit-animation: arrow 2s infinite;
        animation: arrow 2s infinite;
      }

      @-webkit-keyframes arrow {
        0% {
          opacity: 1;
        }
        100% {
          opacity: 0;
          transform: translate((-$quarterArrow), (-$quarterArrow));
        }
      }

      @keyframes arrow {
        0% {
          opacity: 1;
        }
        100% {
          opacity: 0;
          transform: translate((-$quarterArrow), (-$quarterArrow));
        }
      }
    }
  }
}

.popup__pin {
  .pin__form input {
    background-color: var(--theme-bg);
    border-color: var(--theme-bg);
  }

  .popup__buttons {
    margin: 2.5em 0 1em;

    .input__submit {
      margin: 0;
    }
  }
}
