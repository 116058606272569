.mapSearch {
  width: 100%;
  min-height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-color: var(--black);

  .cross {
    position: relative;
    width: 1em;
    height: 1em;

    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 50%;
      width: 2px;
      height: 100%;
      background-color: white;
    }

    &::before {
      transform: rotate(-45deg);
    }

    &::after {
      transform: rotate(45deg);
    }
  }

  .search__main {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  section {
    padding: 1em 1em 0;
    color: white;
    max-height: 90%;

    &.--auto {
      height: auto;

      .search__list {
        height: auto;
      }
    }

    &.--medium {
      max-height: 50%;
    }

    .search__list {
      overflow: auto;
      max-height: calc(100% - 4.16em);

      &.--secondary .list__item {
        margin: 1.75em 0;
      }

      .list__item {
        margin: 1em 0;
      }

      .place__item {
        display: flex;
        align-items: center;

        & > div {
          padding: 0.5em;
        }

        .item__logo #logo path {
          fill: white;
        }

        .item__desc {
          min-width: 60vw;

          div:first-child {
            font-weight: bold;
          }

          div:nth-child(2) {
            font-size: 0.75em;
          }
        }
      }
    }
  }

  .search__panel {
    padding: 1em;
    display: flex;
    align-items: center;

    & > div {
      padding: 0 0.25em;
    }

    .button__cancel {
      padding: 0 0.5rem;
      font-size: 0.75em;
      color: var(--dark-grey);
    }

    .search__input {
      position: relative;
      height: 44px;
      width: 100%;

      input {
        width: 100%;
        height: 100%;
        padding: 0.75em 3.5em 0.75em 0.75em;
        background-color: #212121;
        color: white;
        border: none;
        border-radius: var(--button-radius);
        box-sizing: border-box;
      }

      .input__icon {
        position: absolute;
        padding: 0.5em 0.75em;
        top: 50%;
        right: 0.25em;
        transform: translateY(-50%);
      }
    }
  }
}
