.payment {
  &.--secondary {
    .payment__main .payment__summary {
      background-color: var(--theme-bg);

      &::before,
      &::after {
        background-color: var(--theme-sec-bg);
      }
    }

    .payment__methods .option {
      background-color: var(--theme-bg);

      &:not(.--selected) .option__button {
        background-color: var(--theme-sec-bg);
      }
    }
  }

  .payment__main {
    flex-direction: column;

    & > div {
      padding: 0 1.5em;
      width: calc(100% - 3em);
      max-width: 600px;
    }

    p {
      font-size: 0.875em;
      font-weight: var(--font-weight-semi-bold);
    }

    .payment__summary {
      padding: 1em 0;
      margin: 1em 0;
      position: relative;
      justify-content: space-evenly;
      background-color: var(--theme-sec-bg);
      border-radius: var(--default-radius);

      &::before,
      &::after {
        content: "";
        position: absolute;
        top: 1.5em;
        width: 2em;
        height: 2em;
        border-radius: 50%;
        background-color: var(--theme-bg);
      }

      &::before {
        left: -1em;
      }

      &::after {
        right: -1em;
      }

      .summary__item {
        margin: 1em;

        &.--primary {
          div:first-child {
            font-size: 0.8125em;
          }

          div {
            font-size: 1.75em;
          }
        }

        div:first-child {
          margin-bottom: 0.4em;
          color: var(--dark-grey);
          font-weight: normal;
        }

        div {
          font-size: 0.75em;
          font-weight: var(--font-weight-semi-bold);
        }
      }
    }

    .payment__methods {
      margin-bottom: 2em;

      img {
        max-width: 34px;
      }
    }
  }
}

.popup__payment {
  display: flex;
  flex-direction: column;
  overflow: auto;

  .payment__list {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    overflow: auto;

    .list__item {
      margin: 1em;

      img {
        max-width: 5em;
      }
    }
  }
}

.popup__blik {
  min-height: 50vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  .popup__title {
    div {
      height: 100px;
      img {
        max-height: 100%;
      }
    }
  }
  .popup__form {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    .loading {
      flex-grow: 1;
    }
    input {
      letter-spacing: 6px;
      word-spacing: -2px;
    }
    .input__default {
      border-color: var(--gray-50);
    }
    span {
      font-weight: 600;
    }
  }
  p {
    margin-block-end: 0;
  }
  .--warning {
    font-weight: 500;
    color: var(--red, red);
  }
  .popup__buttons {
    display: flex;
    .--back {
      flex-basis: 50%;
    }
  }
}
