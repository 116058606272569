.loading {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &.--centered {
    position: absolute;
    top: 50%;
    left: 50%;
    width: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    z-index: 21;
  }

  .loading__spinner {
    width: 100px;
    height: 100px;
    transform: translateZ(0) scale(1);
    transform-origin: 0 0;

    .spinner__interior {
      position: absolute;
      top: 10px;
      left: 10px;
      width: 60px;
      height: 60px;
      border: 10px solid var(--theme-text);
      border-top-color: transparent;
      -webkit-animation: rotate 1s linear infinite;
              animation: rotate 1s linear infinite;
      border-radius: 50%;
    }
  }
}

@-webkit-keyframes rotate {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@keyframes rotate {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
