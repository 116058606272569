@import "./assets/styles/_icons.scss";

.app {
  --theme-sec-text: var(--dark-grey);

  &.--lightTheme {
    --theme-bg: var(--light-grey);
    --theme-sec-bg: var(--white);
    --theme-text: var(--black);
    --border-color: #181818;
    --arrow-color: #181818;
    --button-bg: var(--light-grey);
    --button-sec-bg: #f2f2f2;
    --dots-color: var(--border-grey);
    --additional-color: var(--light-grey);
    --circle-border: #f6f6f6;
    --skeleton-base: #ebebeb;
    --skeleton-highlight: #f5f5f5;
    --gate-shadow: #999999;
    --gray-50: #9a9a9a;
    --bottombar-border: #d6d6d6;
  }

  &.--darkTheme {
    --theme-bg: #202020;
    --theme-sec-bg: #2c2c2d;
    --theme-text: #dedee2;
    --border-color: #727275;
    --arrow-color: #dedee2;
    --button-bg: #292929;
    --button-sec-bg: #292929;
    --dots-color: #727275;
    --additional-color: #393939;
    --circle-border: #2b2b2b;
    --skeleton-base: #353535;
    --skeleton-highlight: #393939;
    --gate-shadow: #101010;
    --gray-50: #9a9a9a;
    --bottombar-border: #d6d6d6;
  }

  @-webkit-keyframes darken {
    from {
      background: none;
    }
    to {
      background-color: var(--toast-bg);
    }
  }

  @keyframes darken {
    from {
      background: none;
    }
    to {
      background-color: var(--toast-bg);
    }
  }

  @-webkit-keyframes lighten {
    from {
      background-color: var(--toast-bg);
    }
    to {
      background: none;
    }
  }

  @keyframes lighten {
    from {
      background-color: var(--toast-bg);
    }
    to {
      background: none;
    }
  }

  @-webkit-keyframes moveIn {
    from {
      margin-bottom: -100%;
    }
    to {
      margin-bottom: 0;
    }
  }

  @keyframes moveIn {
    from {
      margin-bottom: -100%;
    }
    to {
      margin-bottom: 0;
    }
  }

  @-webkit-keyframes moveOut {
    from {
      margin-bottom: 0;
    }
    to {
      margin-bottom: -100%;
    }
  }

  @keyframes moveOut {
    from {
      margin-bottom: 0;
    }
    to {
      margin-bottom: -100%;
    }
  }

  .orderTitle {
    color: var(--theme-text);
    padding: 1em;
    display: flex;
    flex-direction: column;
    gap: 4px;
    span:first-child {
      font-size: 1.15rem;
      font-weight: 600;
    }
    span:last-child {
      font-size: 0.875rem;
      font-weight: 500;
      color: var(--theme-sec-text);
    }
  }

  .title {
    padding: 2em 1.5em 1em;
    color: var(--theme-text);

    div:first-child {
      font-size: 1.6875em;
      -webkit-margin-after: 0.2em;
      margin-block-end: 0.2em;
      font-weight: var(--font-weight-semi-bold);
    }

    div {
      font-size: 1.25em;
    }
  }

  .group {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .center {
    display: flex;
    align-items: center;
    justify-content: center;
    &.--gap05 {
      gap: 0.5em;
    }
    &.--gap1 {
      gap: 1em;
    }
  }

  .line {
    display: flex;
    align-items: center;
    justify-content: center;

    &::after {
      content: "";
      margin: 0.75em 0;
      width: 4.6875em;
      max-width: 400px;
      height: 4px;
      background-color: #dedee2;
      border-radius: var(--button-radius);
    }
  }

  .list {
    padding: 1em 21px;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    .list__element {
      // width: 41vmin;
      // height: 41vmin;
      // min-width: 140px;
      // min-height: 140px;
      width: calc(50% - 5px);
      max-width: 200px;
      max-height: 200px;
      padding: 1em;
      // margin: 0.5em;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background-color: var(--theme-sec-bg);
      border-radius: var(--default-radius);
      box-sizing: border-box;
      aspect-ratio: 1;

      &.--big {
        // width: calc(82vmin + 1em);
        width: 100%;
        max-width: 414px;
        aspect-ratio: 2/1;
      }

      &.--black {
        background-color: var(--black);
      }
    }

    @media (orientation: landscape) {
      & {
        padding: 1em;
        justify-content: flex-start;
      }
    }
  }

  .hidableElement {
    display: grid;
    grid-template-rows: 1fr;
    transition: 300ms grid-template-rows, 300ms opacity;
    opacity: 1;

    &.--hidden {
      opacity: 0;
      grid-template-rows: 0fr;
    }

    > * {
      overflow: hidden;
    }
  }
}
