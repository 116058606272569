.notification {
  position: fixed;
  top: 2em;
  left: 1.5em;
  right: 1.5em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--theme-sec-bg);
  border-radius: var(--default-radius);
  box-shadow: 0 0 0.5em var(--shadow);
  z-index: 100;

  &:after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background: var(--theme-bg);
    border-radius: var(--default-radius);
    -webkit-animation: increase 6.2s;
            animation: increase 6.2s;
    transition: all 6.2s;
    z-index: -1;
    opacity: 0;

    @-webkit-keyframes increase {
      100% {
        opacity: 0.8;
        width: 100%;
      }
    }

    @keyframes increase {
      100% {
        opacity: 0.8;
        width: 100%;
      }
    }
  }

  & > * {
    margin: 1em;
  }

  .notification__desc {
    color: var(--theme-text);
  }

  .notification__button {
    padding: 1em;
    font-size: 0.75em;
    color: white;
    background-color: var(--red);
    border-radius: var(--button-radius);
  }
}